import { Table } from 'components/core'
import React from 'react'
import { Box } from 'reflexbox'
import alertSrc from 'assets/mp3/alert.mp3'

const audio = new Audio(alertSrc)

export const initialState = () => ({
  name: '',
  sku: '',
  balance: 0,
  quantity: 0,
  errMessage: '',
})

export const fields = ({ state, setState, ref, message, data }) => {
  return {
    sku: (
      <Box
        ref={ref}
        id="scanner-sku"
        as="input"
        placeholder={message({ id: 'part.field.scanner' })}
        value={state.sku}
        autoComplete="off"
        width={1}
        fontSize={4}
        color="dark.2"
        p={2}
        sx={{
          borderWidth: '0 0 1px',
          borderStyle: 'solid',
          borderColor: 'light.3',
          transition: 'border-color 0.2s linear',
          willChange: 'border-color',
          '&:focus': {
            outline: 'none',
            borderColor: 'accent.1',
          },
        }}
        onChange={async (event) => {
          const sku = event.target.value
          setState({ ...state, sku })
        }}
      />
    ),
    ticketItems: (
      <Table
        showSeq
        headProps={{
          bg: 'light.0',
          sx: {
            position: 'sticky',
            top: 0,
            borderBottom: 'none',
            fontWeight: 300,
            whiteSpace: 'nowrap',
            textAlign: 'left',
          },
        }}
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
          },
          {
            id: 'sku',
            label: 'field.sku',
          },
          {
            id: 'balance',
            label: 'field.balance',
            // align: 'right',
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            // align: 'right',
          },
        ]}
        rows={data.ticketItems}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  getTotalQuantity: (ticketItems) => {
    if (!ticketItems) return 0

    return ticketItems.reduce((result, item) => {
      result += item.quantity || 0
      return result
    }, 0)
  },
  handleSubmit: async (event) => {
    event.preventDefault()

    const result = await action.handleScan(state.sku)
    if (result.errMessage) {
      audio.play()
    }
    setState({
      ...state,
      sku: '',
      name: result.name,
      balance: result.balance,
      quantity: result.quantity,
      errMessage: result.errMessage,
    })
  },
})
