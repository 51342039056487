import { request } from 'utilities/request'

export const getLocation = (locations, locationId) => {
  if (!locations || !locationId) return {}
  return locations.find(({ id }) => id === locationId)
}

export const parseRecipient = (recipient) => {
  const extra = recipient.extra || {}
  return {
    recipientContact: extra.contact || '',
    recipientAddress: extra.address || '',
    recipientEmail: extra.email || '',
    recipientPhone: extra.phone || '',
    recipientCellphone: extra.cellphone || '',
  }
}

export const fetchLocations = async (app, session, input) => {
  const variables = { input }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  return data.locations
}
