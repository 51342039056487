import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { createExcel } from 'utilities/excel'
import { SessionContext, AppContext } from 'contexts'
import { columns, filters, handlers } from 'models/inventoryList'
import { Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import InventoryDrawer from 'views/InventoryDrawer'
import { initialPage } from 'utilities/pagination'
import { MdFileUpload, MdPrint } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [warehouses, setWarehouses] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState([])
  const { handleLoad, handlePrint, handleExport } = handlers({
    session,
    app,
    state,
    message,
    setState,
    setFilterValues,
    setPagination,
    setWarehouses,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <Page
      title="inventory.title.warehouseList"
      navId="inventoryWarehouseList"
      action={
        <>
          <Link
            mr={3}
            icon={<MdPrint />}
            text="btn.print"
            onClick={() => handlePrint({ filterValues })}
          />
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
        </>
      }
    >
      <Section>
        <Table
          columns={columns({ action: { handleOpen } })}
          rows={state}
          filters={filters({ warehouses })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <InventoryDrawer value={value} open={open} action={{ handleClose }} />
    </Page>
  )
}
