import React from 'react'
import { Box } from 'reflexbox'
import { renderMessage } from 'utilities/app'
import Heading from './Heading'

export default ({
  title,
  titleProps,
  menu,
  menuProps,
  titleExtra,
  action,
  actionProps,
  wrap = false,
  ...props
}) => {
  if (!menu && !title && !action) return null
  return (
    <Box
      py={2}
      px={3}
      variant="primary"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={wrap ? 'wrap' : 'nowrap'}
      sx={{ gap: 3 }}
      {...props}
    >
      {menu && <Box {...menuProps}>{menu}</Box>}
      {title && (
        <Heading alignItems="center" {...titleProps}>
          {renderMessage(title)}
          {titleExtra}
        </Heading>
      )}
      {action && <Box {...actionProps}>{action}</Box>}
    </Box>
  )
}
