import React, { useContext } from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { SessionContext } from 'contexts'
import { Box } from 'reflexbox'
import { Center, Modal, Button } from 'components/core'
import { renderMessage } from 'utilities/app'

export default ({ children, ...props }) => {
  const { state, dispatch } = useContext(SessionContext)
  const { open, title, text, onSubmit } = state.confirmModal

  const handleClose = () =>
    dispatch({
      type: CONFIRM_SET,
      item: { open: false, title: '', text: '', onSubmit: () => {} },
    })

  const handleSubmit = async () => {
    const ok = await onSubmit()
    if (ok) {
      handleClose()
    }
  }

  return (
    <>
      <Modal
        headerProps={{ display: 'flex', variant: 'warning' }}
        title={title}
        open={open}
        onClose={handleClose}
        width={300}
        {...props}
      >
        <Box mt={4}>{text ? renderMessage(text) : children}</Box>
        <Center mt={4}>
          <Button
            mr={2}
            variant="secondary"
            onClick={handleClose}
            text="btn.cancel"
          />
          <Button variant="primary" onClick={handleSubmit} text="btn.confirm" />
        </Center>
      </Modal>
    </>
  )
}
