import { ADDRESS_SET } from 'constants/actionType'
import { request } from 'utilities/request'

export async function setAddressOptions({
  app,
  session,
  cityMap,
  districtMap,
}) {
  if (Object.keys(cityMap).length > 0 && Object.keys(districtMap).length > 0) {
    return
  }

  fetchAddressOptions({ app, session }).then((data) => {
    app.dispatch({
      type: ADDRESS_SET,
      cities: data.cities,
      districts: data.districts,
    })
  })
}

export async function fetchAddressOptions({ app, session }) {
  const variables = { countryCode: 'TW' }
  const query = `
    query($countryCode: String!) {
      cities(countryCode: $countryCode) {
        name
      }
      districts(countryCode: $countryCode) {
        cityName
        name
        zipcode
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  return data
}
