export default {
  'technician.title.list': '維修人員',
  'technician.title.add': '新增維修人員',
  'technician.title.edit': '更改維修人員',
  'technician.title.delete': '刪除維修人員',
  'technician.message.delete': '確認刪除維修人員?',
  'technician.field.name': '名稱',
  'technician.status.ACTIVE': '正常',
  'technician.status.INACTIVE': '停用',
}
