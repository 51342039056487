import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/inventoryCheckoutDrawer'
import { Drawer, Table } from 'components/core'

export default ({ value, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const { handleLoad } = handlers({ setState, session, app, value })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      title={`inventory.title.checkoutList`}
      open={open}
      onClose={action.handleClose}
    >
      <Table columns={columns} rows={state} onChange={handleLoad} />
    </Drawer>
  )
}
