import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/core/Button'

export default ({ href, children, ...props }) => {
  const history = useHistory()
  return (
    <Button onClick={() => history.push(href)} {...props}>
      {children}
    </Button>
  )
}
