import React from 'react'
import { Box } from 'reflexbox'

export default ({
  cols = '1fr',
  rows = '1fr',
  colGap = 0,
  rowGap = 0,
  gap = 0,
  children,
  ...props
}) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: cols,
      gridTemplateRows: rows,
      gridColumnGap: colGap || gap,
      gridRowGap: rowGap || gap,
    }}
    {...props}
  >
    {children}
  </Box>
)
