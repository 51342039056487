import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  handleTextChange,
  validateForm,
  showDate,
  getSelectOption,
  handleSelectChange,
  getDate,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Definition, Table } from 'components/core'
import { TextArea, Select, NumberInput, DateInput } from 'components/form'
import { printHtml } from 'utilities/print'
import {
  getInitialItemInput,
  handleDelete,
  handleItemAdd,
  handleItemDelete,
  setBalances,
} from 'actions/ticket'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { getPartOptions, getSkuOptions } from 'actions/part'

export const initialState = (value = {}, message) => ({
  id: value.id,
  status: value.status || 'PENDING',
  hash: value.hash,
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  inputValues: getInitialItemInput(),
  editValues: {},
  parts: value.parts || [],
  partOptions: getPartOptions(value.parts),
  skuOptions: getSkuOptions(value.parts),
  locations: value.locations,
  locationOptions: getLocationOptions(value.locations, message),
  toLocationName: value.toLocationName || '',
  types: getAdjustTypes(message),
  ...initializeState({
    transDate: getDate(value.transDate),
    toLocationId: getSelectOption(
      value.locations,
      value.toLocationId,
      'id',
      'name',
    ),
    type: getSelectOption(
      getAdjustTypes(message),
      value.type,
      'value',
      'label',
      false,
    ),
    memo: value.memo || '',
  }),
})

export function getAdjustTypes(message) {
  return [
    { value: 'SELF_USE', label: message({ id: 'adjust.type.SELF_USE' }) },
    { value: 'DISCARD', label: message({ id: 'adjust.type.DISCARD' }) },
    { value: 'ADJUST', label: message({ id: 'adjust.type.ADJUST' }) },
  ]
}

function getLocationOptions(locations, message) {
  if (!locations) return []
  const warehouses = []
  const techs = []
  locations.forEach(({ type, id, name }) => {
    const result = { label: name, value: id }

    switch (type) {
      case 'TECHNICIAN':
        techs.push(result)
        break
      default:
        warehouses.push(result)
    }
  })
  return [
    { label: message({ id: 'location.type.WAREHOUSE' }), options: warehouses },
    { label: message({ id: 'location.type.TECHNICIAN' }), options: techs },
  ]
}

const validation = {
  toLocationId: [{ type: 'required', message: 'error.required' }],
  type: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'id', label: 'field.ticketId' },
  {
    id: 'transDate',
    label: 'field.date',
    render: (state) => showDate(state.transDate),
  },
  {
    id: 'toLocationId',
    label: 'adjust.field.location',
    render: (state) => state.toLocationName,
  },
  {
    id: 'type',
    label: 'field.type',
    render: (state) => state.type.label,
  },
  { id: 'memo', label: 'field.memo' },
]

const columns = [
  { id: 'productVariantName', label: 'field.partName' },
  { id: 'sku', label: 'field.sku' },
  {
    id: 'location',
    label: 'part.field.location',
    render: ({ row }) => row.extra?.location,
  },
  {
    id: 'unitPrice',
    label: 'field.unitPrice',
    align: 'right',
    render: ({ row }) => row.extra?.unitPrice,
  },
  { id: 'quantity', label: 'field.quantity', align: 'right' },
]

export const labels = ({ state }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.ticketItems = (
    <Table showSeq columns={columns} rows={state.ticketItems} />
  )
  return content
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="field.date"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    toLocationId: (
      <Select
        id="toLocationId"
        label="adjust.field.location"
        placeholder="adjust.field.location"
        isClearable={false}
        options={state.locationOptions}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId', async ({ value }) => {
          const { locations, ticketItems, oldTicketItems } = state
          const location = locations.find(({ id }) => id === value)
          const balances = await fetchBalances(
            app,
            session,
            location.type,
            location.id,
            ticketItems.map((item) => item.productVariantId),
            oldTicketItems,
          )
          setBalances(ticketItems, balances)
          const inputValues = getInitialItemInput()
          return { ticketItems, inputValues }
        })}
        errMsg={state.__error__.toLocationId}
      />
    ),
    type: (
      <Select
        isClearable={false}
        id="type"
        label="adjust.field.type"
        placeholder="adjust.field.type"
        options={state.types}
        value={state.type}
        onChange={onSelectChange('type')}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    ticketItems: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.partOptions}
                onChange={async ({ value }) => {
                  const { locations, toLocationId } = state
                  const location = locations.find(
                    ({ id }) => id === toLocationId.value,
                  )
                  const part = state.parts.find(({ id }) => id === value)
                  const unitPrice = part.price || 0
                  if (!location) {
                    return {
                      sku: { value: part.id, label: part.sku },
                      unitPrice,
                      balance: 0,
                    }
                  }

                  const balance = await fetchBalance(
                    app,
                    session,
                    location.type,
                    location.id,
                    value,
                    state.oldTicketItems,
                  )
                  return {
                    sku: { value: part.id, label: part.sku },
                    unitPrice,
                    balance,
                  }
                }}
              />
            ),
            getValue: (row) => {
              return getSelectOption(state.partOptions, row.productVariantId)
            },
          },
          {
            id: 'sku',
            label: 'field.sku',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.skuOptions}
                onChange={async ({ value }) => {
                  const { locations, toLocationId } = state
                  const location = locations.find(
                    ({ id }) => id === toLocationId.value,
                  )
                  const part = state.parts.find(({ id }) => id === value)
                  const unitPrice = part.price || 0
                  const balance = await fetchBalance(
                    app,
                    session,
                    location.type,
                    location.id,
                    value,
                    state.oldTicketItems,
                  )
                  const result = { value: part.id, label: part.name }
                  return { productVariantName: result, unitPrice, balance }
                }}
              />
            ),
            getValue: (row) => {
              return getSelectOption(state.skuOptions, row.productVariantId)
            },
          },
          {
            id: 'balance',
            label: 'field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '96px',
            align: 'right',
            renderInput: () => <NumberInput />,
            defaultInputValue: 1,
          },
        ]}
        rows={state.ticketItems}
        showAddInput
        showDeleteIcon
        inputValues={state.inputValues}
        editValues={state.editValues}
        onInputChange={(value) => setState({ ...state, inputValues: value })}
        onEditChange={(value) => setState({ ...state, editValues: value })}
        onAdd={({ row }) =>
          handleItemAdd({ session, state, setState, row, checkBalance: false })
        }
        onEdit={({ row, index }) => {
          const { sku, productVariantName, balance } = row
          if (!productVariantName.value) return false

          const productVariantId = productVariantName.value
          const part = state.parts.find(({ id }) => id === productVariantId)
          const unitPrice = part.price || 0
          const quantity = parseInt(row.quantity)
          const ticketItems = cloneDeep(state.ticketItems)
          const ticketItem = {
            ...ticketItems[index],
            productVariantId,
            productVariantName: productVariantName.label,
            sku: sku.label,
            balance: parseInt(balance),
            quantity: parseInt(quantity),
            extra: { unitPrice },
          }
          ticketItems.splice(index, 1, ticketItem)
          setState({ ...state, ticketItems })
          return true
        }}
        onDelete={({ index }) => handleItemDelete({ state, setState, index })}
      />
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  profile,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, profile, id })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editAdjust(state, app, session)
      : await addAdjust(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartAdjustTicket

    history.push(`/adjust/${id}/view`)
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partAdjust', { session, app, id, hash })
    if (!ok) return false

    history.push('/adjust')
    return true
  },
  handlePrint: () => {
    const title = 'adjust.title.view'
    const field = defs.map(({ id, label }) => ({ label, value: state[id] }))
    const list = { columns, rows: state.ticketItems }
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: list },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, profile, id }) {
  const locationInput = { type: ['PART_WAREHOUSE', 'TECHNICIAN'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      parts {
        id
        name
        sku
        price
        extra
      }
      partAdjustTicket(id: $id) {
        toLocationId
        toLocationName
        ticketNo
        transDate
        status
        hash
        extra
      }
      partAdjustTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partAdjustTicket || {}
  const ticketItems = data.partAdjustTicketItems || []
  const oldTicketItems = cloneDeep(ticketItems)
  const { toLocationId } = ticket

  if (profile === 'edit' && ticketItems.length > 0) {
    const location = data.locations.find(({ id }) => id === toLocationId)
    const balances = await fetchBalances(
      app,
      session,
      location.type,
      location.id,
      ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
    )
    setBalances(ticketItems, balances)
  }

  return {
    id,
    hash: ticket.hash,
    ticketNo: ticket.ticketNo,
    transDate: ticket.transDate,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    type: ticket.extra?.type,
    memo: ticket.extra?.memo,
    status: ticket.status,
    ticketItems,
    oldTicketItems,
    parts: data.parts,
    locations: data.locations,
  }
}

async function addAdjust(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartAdjustTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editAdjust(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartAdjustTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, toLocationId, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate,
    ticketNo: state.ticketNo,
    toLocationId: toLocationId.value,
    extra: {
      type: state.type.value,
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}
