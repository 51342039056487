import React from 'react'
import { Center, Text } from 'components/core'
import { FaInbox } from 'react-icons/fa'

export default ({ iconSize = '48px', ...props }) => (
  <Center
    flexDirection="column"
    height="100%"
    bg="light.1"
    color="light.3"
    {...props}
  >
    <FaInbox fontSize={iconSize} />
    <Text px={3}>NO DATA</Text>
  </Center>
)
