import React from 'react'
import Page from 'components/Page'

export default () => {
  return (
    <Page title="dashboard.title" navId="dashboard">
      &nbsp;
    </Page>
  )
}
