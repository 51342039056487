import { formatDate } from './date'
import { getDate } from './form'

export const createExcel = ({
  message,
  title,
  timestamp = true,
  cols,
  rows,
  filterValues = [],
}) => {
  title = title ? message({ id: title }) : 'report'
  const filename = timestamp ? `${title}_${getDate()}.xlsx` : `${title}.xlsx`
  const data = []

  const header = []
  for (const col of cols) {
    if (!col.label) continue
    header.push(message({ id: col.label }))
  }
  data.push(header)

  for (const [index, row] of rows.entries()) {
    const rowVals = []

    cols.forEach((col) => {
      if (col.label) {
        rowVals.push(getCellVal(col, row, index))
      }
    })

    data.push(rowVals)
  }

  import('xlsx').then((XLSX) => {
    const wb = XLSX.utils.book_new()
    const ws1 = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, ws1, title)

    if (filterValues.length > 0) {
      const filterData = filterValues.map(({ label, value }) => {
        if (typeof value === 'string') return [label, value]
        if (Array.isArray(value)) return [label, value.join(', ')]
        return [label, value.label]
      })
      const ws2 = XLSX.utils.aoa_to_sheet(filterData)
      XLSX.utils.book_append_sheet(wb, ws2, message({ id: 'filter.title' }))
    }

    XLSX.writeFile(wb, filename)
  })
}

function getCellVal(col, row, index) {
  if (col.render) return col.render({ row, index })
  return row[col.id]
}

export const readExcel = (file) => {
  return new Promise((resolve) => {
    import('xlsx').then((XLSX) => {
      const wb = XLSX.read(file, { type: 'binary' })
      const ws = wb.Sheets[wb.SheetNames[0]]
      updateSheetRange(XLSX, ws)
      resolve(XLSX.utils.sheet_to_json(ws))
    })
  })
}

function updateSheetRange(XLSX, ws) {
  var range = { s: { r: 20000000, c: 20000000 }, e: { r: 0, c: 0 } }
  Object.keys(ws)
    .filter((x) => x.charAt(0) !== '!')
    .map(XLSX.utils.decode_cell)
    .forEach(function (x) {
      range.s.c = Math.min(range.s.c, x.c)
      range.s.r = Math.min(range.s.r, x.r)
      range.e.c = Math.max(range.e.c, x.c)
      range.e.r = Math.max(range.e.r, x.r)
    })
  ws['!ref'] = XLSX.utils.encode_range(range)
}

export function convertDate(excelDate) {
  if (!excelDate) return ''

  let date = null
  if (typeof excelDate === 'string') {
    date = new Date(excelDate)
  } else {
    date = new Date(Math.round((excelDate - 25569) * 86400 * 1000))
  }
  return formatDate(date)
}
