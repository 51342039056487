import { request } from 'utilities/request'
import { LOGOUT } from 'constants/actionType'

export async function handleLogout({ app, session, history }) {
  const { staffId } = app.state.staff
  const { refreshToken } = app.state.token
  const variables = { staffId, refreshToken }
  const query = `
    mutation($staffId: ID!, $refreshToken: String!) {
      deleteToken(staffId: $staffId, refreshToken: $refreshToken)
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return

  if (data.deleteToken) {
    app.dispatch({ type: LOGOUT })
    history.push('/login')
  }
}
