import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Text } from 'components/core'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const setBalances = (ticketItems, balances, key = 'balance') => {
  ticketItems.forEach((ticketItem) => {
    const balance = balances.find(
      (item) => ticketItem.productVariantId === item.productVariantId,
    )
    ticketItem[key] = balance ? balance.quantity : 0
  })
}

export const getEditableBalance = (profile, oldQuantity, balance) => {
  const quantity = parseInt(oldQuantity || 0)
  const newBalance = parseInt(balance || 0)
  if (profile !== 'edit') return newBalance
  return newBalance + quantity
}

export const getDisplayBalance = (profile, oldQuantity, balance) => {
  const quantity = parseInt(oldQuantity || 0)
  const newBalance = getEditableBalance(profile, oldQuantity, balance)
  if (newBalance > 0 && newBalance >= quantity) return newBalance
  return <Text color="error.1">{newBalance}</Text>
}

export const getRecipients = (value = [], message) => {
  return [
    {
      id: '__INDIVIDUAL__',
      name: message({ id: 'dispatch.recipient.individual' }),
    },
    ...value,
  ]
}

export async function handleDelete(module, { session, app, id, hash, action }) {
  const name = module.charAt(0).toUpperCase() + module.slice(1)
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      cancel${name}Ticket(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok && action) action.handleLoad()
  return ok
}

export function getInitialItemInput(
  { initPrice = false } = {},
  extraInput = {},
) {
  const result = {
    productVariantId: '',
    productVariantName: '',
    sku: '',
    balance: 0,
    unitPrice: 0,
    quantity: 1,
    ...extraInput,
  }

  if (initPrice) result.price = 0

  return result
}

export function handleItemAdd({
  session,
  state,
  setState,
  row,
  checkBalance = true,
  extraKeys = [],
}) {
  const { sku, productVariantName } = row
  if (!productVariantName.value) return false

  const balance = parseInt(row.balance)
  const quantity = parseInt(row.quantity)
  if (checkBalance && quantity > balance) {
    session.dispatch({
      type: ALERT_ADD,
      item: {
        type: 'error',
        message: 'error.inventoryBalance.insufficientQuantity',
      },
    })
    return false
  }

  const productVariantId = productVariantName.value
  const part = state.parts.find(({ id }) => id === productVariantId)
  const unitPrice = parseFloat(row.unitPrice || 0)
  const price =
    row.price === undefined
      ? parseFloat(unitPrice * quantity)
      : parseFloat(row.price)
  const ticketItems = cloneDeep(state.ticketItems)
  const extra = { location: part.extra?.location, unitPrice }
  extraKeys.forEach((key) => {
    extra[key] = row[key]
  })
  ticketItems.push({
    productVariantId,
    productVariantName: productVariantName.label,
    sku: sku.label,
    balance,
    quantity,
    price,
    extra,
  })
  const initExtra = extraKeys.reduce((result, item) => {
    result[item] = ''
    return result
  }, {})
  const initPrice = row.price !== undefined
  const inputValues = getInitialItemInput({ initPrice }, initExtra)
  setState({ ...state, ticketItems, inputValues })
  return true
}

export function handleItemDelete({ state, setState, index }) {
  const ticketItems = cloneDeep(state.ticketItems)
  ticketItems.splice(index, 1)
  setState({ ...state, ticketItems })
}

export function getTicketType(ticketType) {
  switch (ticketType) {
    case 'PART_BUY':
      return 'buy'
    case 'PART_CHECKOUT':
      return 'checkout'
    case 'PART_CHECKIN':
      return 'checkin'
    case 'PART_SELL':
      return 'sell'
    case 'PART_ADJUST':
      return 'adjust'
    case 'PART_MOVE':
      return 'move'
    default:
      return ticketType
  }
}
