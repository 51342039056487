import technician from './technician/zh-TW'
import part from './part/zh-TW'
import buy from './buy/zh-TW'
import adjust from './adjust/zh-TW'
import move from './move/zh-TW'
import checkin from './checkin/zh-TW'
import checkout from './checkout/zh-TW'
import sell from './sell/zh-TW'
import inventory from './inventory/zh-TW'
import warehouse from './warehouse/zh-TW'
import report from './report/zh-TW'

export default {
  'error.required': '此欄位為必填欄',
  'error.min': '最小值需為{val}',
  'error.minLength': '最少字符需為{val}',
  'error.maxLength': '最多字符為{val}',
  'error.token.expired': '請重新登錄！',
  'error.token.invalid': '帳戶與密碼不符！',
  'error.staff.notFound': '帳戶與密碼不符！',
  'error.staff.invalidCredential': '帳戶與密碼不符！',
  'error.role.exist': '角色已存在！',
  'error.location.duplicate': '經銷商已存在！',
  'error.role.notAllowed': '登錄用戶無執行權限',
  'btn.submit': '提交',
  'btn.confirm': '確認',
  'btn.approve': '核准',
  'btn.reject': '駁回',
  'btn.save': '儲存',
  'btn.back': '返回',
  'btn.cancel': '取消',
  'btn.preview': '預覽',
  'btn.close': '關閉',
  'btn.add': '新增',
  'btn.edit': '修改',
  'btn.delete': '刪除',
  'btn.more': '更多',
  'btn.export': '匯出',
  'btn.print': '列印',
  'btn.search': '搜尋',
  'placeholder.textfield': '請輸入{label}',
  'placeholder.select.create': '新增「{name}」',
  'save.success': '儲存成功！',
  'submit.success': '提交成功！',
  'submit.failed': '提交失敗！',
  'title.delete': '刪除{name}',
  'title.approve': '核准{name}',
  'message.delete': '確認刪除{name}?',
  'message.approve': '確認核准{name}?',
  'elevator.true': '有電梯',
  'elevator.false': '無電梯',
  'field.seq': '序號',
  'field.ticketId': '單號',
  'field.ticketNo': '廠商單號',
  'field.type': '類型',
  'field.warehouse': '倉庫',
  'field.partFilter': '輸入搜尋零件名稱',
  'field.partName': '零件名稱',
  'field.sku': '零件代碼',
  'field.date': '日期',
  'field.address': '地址',
  'field.technician': '維修人員',
  'field.balance': '庫存數量',
  'field.quantity': '數量',
  'field.price': '金額',
  'field.unitPrice': '單價',
  'field.subTotal': '小計',
  'field.total': '總計',
  'field.memo': '備註',
  'field.status': '狀態',
  'type.true': '是',
  'type.false': '否',
  'status.ACTIVE': '已完成',
  'status.INACTIVE': '已刪除',
  'status.PENDING': '待審核',
  'status.CALLBACK_PENDING': '待三方確認',
  'module.buy': '進倉單',
  'module.sell': '銷貨單',
  'module.checkout': '攜出單',
  'module.checkin': '歸還單',
  'module.adjust': '調整單',
  'module.move': '調撥單',
  'module.part': '零件設定',
  'app.stock': '慎康倉儲系統',
  'app.repair': '慎康維修系統',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'app.password': '登錄帳密',
  'app.login.required': '請先登錄會員！',
  'login.title': '登錄管理平台',
  'login.field.username': '帳號',
  'login.field.password': '密碼',
  'login.btn.login': '登錄',
  'login.success': '成功登錄',
  'logout.success': '成功登出',
  'filter.title': '搜尋條件',
  'filter.op.eq': '等於',
  'filter.clear': '全部清除',
  'dashboard.title': 'Dashboard',
  'ticket.title': '零件管理',
  'inventory.title': '訂單管理',
  'customer.title': '客戶管理',
  'plugin.title': '插件設置',
  'setting.title': '系統設置',
  'location.type.WAREHOUSE': '倉庫',
  'location.type.TECHNICIAN': '維修人員',
  'ticketType.PART_BUY': '進倉',
  'ticketType.PART_SELL': '銷貨',
  'ticketType.PART_CHECKOUT': '攜出',
  'ticketType.PART_CHECKIN': '歸還',
  'ticketType.PART_ADJUST': '調整',
  'ticketType.PART_MOVE': '調撥',
  ...part,
  ...technician,
  ...buy,
  ...adjust,
  ...move,
  ...inventory,
  ...checkin,
  ...checkout,
  ...sell,
  ...process,
  ...warehouse,
  ...report,
}
