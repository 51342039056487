import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import AppContext from 'contexts/AppContext'
import { NAV_SET } from 'constants/actionType'
import { Box, Flex } from 'reflexbox'
import { Navbar, Center } from 'components/core'

export default ({
  navId,
  title,
  titleExtra,
  action,
  showFooterAction = false,
  fullWidth,
  children,
  ...props
}) => {
  const app = useContext(AppContext)
  const { formatMessage } = useIntl()

  useEffect(() => {
    document.title = formatMessage({ id: title })

    if (navId) {
      app.dispatch({ type: NAV_SET, id: navId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navId, title])

  return (
    <Flex
      pb={7}
      width={1}
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        overflowX: 'auto',
      }}
      {...props}
    >
      <Box
        width={fullWidth ? '100%' : ['100%', '100%', '100%', '992px']}
        px={fullWidth ? 4 : 0}
        py={4}
      >
        <Navbar
          variant="toolbar"
          px={3}
          mb={4}
          title={title}
          titleProps={{ fontSize: 4 }}
          titleExtra={titleExtra}
          action={action}
        />
        {children}
        {showFooterAction && (
          <Center mt={4} px={3} justifyContent="flex-end">
            {action}
          </Center>
        )}
      </Box>
    </Flex>
  )
}
