import React from 'react'
import { useIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'
import { Icon } from 'components/core'

export default ({
  show = true,
  icon,
  iconProps,
  rightIcon,
  rightIconProps,
  text,
  tooltip,
  children,
  onClick,
  ...props
}) => {
  if (!show) return null
  const { formatMessage: message } = useIntl()

  return (
    <Box
      as="button"
      type="button"
      tx="buttons"
      variant="primary"
      display="inline-flex"
      alignItems="center"
      onClick={onClick}
      {...props}
    >
      <Icon mr={text || children ? 2 : 0} {...iconProps} value={icon} />
      {text ? message({ id: text }) : children}
      {rightIcon && (
        <Flex
          alignItems="center"
          fontSize={3}
          ml={children ? 2 : 0}
          {...rightIconProps}
        >
          {rightIcon}
        </Flex>
      )}
    </Box>
  )
}
