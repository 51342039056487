import React from 'react'
import { request } from 'utilities/request'
import { Link } from 'components/core'
import { selectFilter, textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { getSelectOptions } from 'utilities/form'
import { printHtml } from 'utilities/print'
import { formatDate } from 'utilities/date'

export const filters = (data) => [
  {
    id: 'tech',
    label: `inventory.field.tech`,
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.techs,
      }),
  },
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter,
  },
]

export const columns = ({ action }) => [
  {
    id: 'locationName',
    label: `inventory.field.tech`,
  },
  {
    id: 'productVariantName',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'productLocation',
    label: 'part.field.location',
  },
  {
    id: 'price',
    label: 'part.field.price',
    align: 'right',
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleOpen({
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.quantity}
      </Link>
    ),
  },
]

export const handlers = ({
  session,
  app,
  state,
  message,
  setState,
  setFilterValues,
  setPagination,
  setTechs,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({
      session,
      app,
      filterValues,
      pagination,
    })
    setFilterValues(filterValues)
    setState(resp.data)
    setTechs(resp.techs)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'inventory.title.techList'
    const cols = columns({})
    const { data } = await getData({ session, app, filterValues })
    createExcel({ message, title, cols, rows: data, filterValues })
  },
  handlePrint: async ({ filterValues }) => {
    const title = 'inventory.title.techList'
    const header = {
      title: { id: title },
      date: formatDate(new Date()),
    }
    const { data } = await getData({ session, app, filterValues })
    const list = { columns: columns({}), rows: data }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, header, content, message })
  },
})

async function getData({ session, app, filterValues = [], pagination }) {
  const variables = {
    locationInput: { type: ['TECHNICIAN'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: InventoryQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      inventoryPartCount(input: $input)
      inventoryPartList(input: $input) {
        locationId
        locationName
        productVariantId
        productLocation
        quantity
        price
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination || {}
  return {
    data: data.inventoryPartList,
    pagination: getPagination(page, countPerPage, data.inventoryPartCount),
    techs: getSelectOptions(data.locations),
  }
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const tech = filterValues.find(({ id }) => id === 'tech')
  const input = {
    productVariantName: name ? name.value : null,
    sku: sku ? sku.value : null,
    locationType: 'TECHNICIAN',
    locationId: tech ? tech.value.value : null,
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
