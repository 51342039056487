import React, { useState, useEffect } from 'react'
import { keyframes } from '@emotion/core'
import { Box } from 'reflexbox'

const loading = keyframes`
  from { left: -200px; width: 30%; }
  50% { width: 30%; }
  70% { width: 70%; }
  80% { left: 50%; }
  95% { left: 120%; }
  to { left: 100%; }
`

const Wrapper = ({ hidden, children }) => (
  <Box
    height="4px"
    width="100%"
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 10000,
      visibility: hidden ? 'hidden' : 'visible',
      opacity: hidden ? 0 : 1,
      transition: 'visibility 0s linear 500ms, opacity 500ms',
    }}
  >
    {children}
  </Box>
)

const Bar = ({ children }) => (
  <Box
    height="100%"
    width="100%"
    bg="rgba(200, 200, 200, 0.4)"
    overflow="hidden"
    sx={{ position: 'relative' }}
  >
    {children}
  </Box>
)

const Progress = () => (
  <Box
    width="200px"
    height="4px"
    bg="accent.1"
    sx={{
      position: 'absolute',
      left: '-200px',
      animation: `${loading} 2s linear infinite`,
    }}
  />
)

export default ({ loading }) => {
  const [visible, setVisible] = useState(false)
  const [t, setTimer] = useState(null)

  useEffect(() => {
    if (loading && !visible) {
      setTimer(
        setTimeout(() => {
          setVisible(true)
        }, 250),
      )
    }

    if (!loading && visible) {
      setVisible(false)
    }

    if (!loading) {
      clearTimeout(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, visible])

  return (
    <Wrapper hidden={!visible}>
      <Bar>
        <Progress />
      </Bar>
    </Wrapper>
  )
}
