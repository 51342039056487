import React from 'react'
import { Box } from 'reflexbox'

const Container = ({ children, bgImage, sx, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundImage: bgImage ? `url(${bgImage})` : 'none',
      ...sx,
    }}
    // {...props}
  >
    {children}
  </Box>
)

const Mask = ({ sx }) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      bg: 'dark.3',
      opacity: 0.8,
      ...sx,
    }}
  />
)

const Content = ({ children, sx }) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2,
      ...sx,
    }}
  >
    {children}
  </Box>
)

export default ({ bgImage, bgSx, maskSx, contentSx, children, ...props }) => (
  <Container bgImage={bgImage} sx={bgSx} {...props}>
    <Mask sx={maskSx} />
    {children && <Content sx={contentSx}>{children}</Content>}
  </Container>
)
