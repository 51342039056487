import React, { useState, useRef, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import { Link, Text } from 'components/core'
import { MdMoreVert } from 'react-icons/md'

export default ({
  show = true,
  icon,
  label,
  children,
  contentSx,
  contentProps,
  linkProps,
  ...props
}) => {
  if (!show) return null

  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <Flex
      ref={ref}
      height="100%"
      onClick={() => setOpen(!open)}
      // onMouseEnter={() => setOpen(true)}
      // onMouseLeave={() => setOpen(false)}
      // onFocus={() => setOpen(true)}
      // onBlur={() => setOpen(false)}
      sx={{
        position: 'relative',
      }}
      {...props}
    >
      <Link mx={2} fontSize={4} onClick={() => setOpen(!open)} {...linkProps}>
        {icon !== undefined ? icon : <MdMoreVert />}
        {label && <Text ml={2}>{label}</Text>}
      </Link>
      <Box
        display={open ? 'block' : 'none'}
        py={2}
        mt={4}
        bg="light.0"
        sx={{
          position: 'absolute',
          minWidth: '100px',
          top: 0,
          right: 0,
          zIndex: 1101,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'light.2',
          borderRadius: '3px',
          boxShadow: 'small',
          whiteSpace: 'noWrap',
          ...contentSx,
        }}
        {...contentProps}
      >
        <Flex px={3} flexDirection="column">
          {children}
        </Flex>
      </Box>
    </Flex>
  )
}
