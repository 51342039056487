import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import InputMask from 'react-input-mask'
import FormField from './FormField'
import { getCanonicalValue, getNumericValue } from 'utilities/string'

const maskChar = '_'
const phoneMask = '(99)99999999#9999'
const mobileMask = '9999-999-999'

export default ({
  id,
  label,
  required,
  errMsg,
  fieldProps,
  type = 'phone',
  value,
  onChange,
  ...props
}) => {
  const theme = useTheme()
  const { space, fontSizes, colors } = theme
  const [borderColor, setBorderColor] = useState(getBorderColor(false))
  const mask = type === 'phone' ? phoneMask : mobileMask

  function getBorderColor(isFocused) {
    if (!!errMsg) return colors.error[1]
    return isFocused ? colors.accent[1] : colors.light[3]
  }

  return (
    <FormField
      id={id}
      label={label}
      required={required}
      errMsg={errMsg}
      {...fieldProps}
    >
      <InputMask
        mask={mask}
        maskPlaceholder={maskChar}
        alwaysShowMask
        value={getValue(type, value)}
        onChange={({ target }) => onChange(target.value)}
        onFocus={() => setBorderColor(getBorderColor(true))}
        onBlur={({ target }) => {
          onChange(formatValue(type, target.value))
          setBorderColor(getBorderColor(false))
        }}
        style={{
          width: '100%',
          minHeight: '38px',
          padding: space[2],
          margin: space[0],
          fontSize: fontSizes[1],
          color: colors.dark[2],
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor,
          borderRadius: '4px',
          transition: 'border-color 0.2s linear',
          willChange: 'border-color',
          outline: 'none',
        }}
        {...props}
      />
    </FormField>
  )
}

function formatValue(type, value) {
  let data = value.replaceAll(maskChar, '')
  if (type === 'phone') {
    if (getNumericValue(data).length < 7) return ''

    if (data.charAt(data.length - 1) === '#') {
      data = data.substring(0, data.length - 1)
    }
    return data.substring(0, phoneMask.length)
  }

  if (getNumericValue(data).length !== 10) return ''
  return data.substring(0, mobileMask.length)
}

function getValue(type, value) {
  const data = getCanonicalValue(value)
  return type === 'phone' ? getPhoneValue(data) : getMobileValue(data)
}

function getPhoneValue(value) {
  let [stem, ext] = `${value || ''}`.split('#')
  const phone = (stem + maskChar.repeat(10)).slice(0, 10)
  if (!ext) return phone
  return phone + '#' + ext
}

function getMobileValue(value) {
  return `${value}`
}
