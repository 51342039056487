import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { getTicketType } from 'actions/ticket'
import { Link, Text } from 'components/core'

export const columns = (message) => [
  {
    id: 'ticketId',
    label: `field.ticketId`,
    renderHtml: ({ row: { ticketType, ticketId } }) => {
      const type = getTicketType(ticketType)
      return (
        <Link
          variant="primaryLink"
          href={`${process.env.REACT_APP_REPAIR_URL}/${type}/${ticketId}/view`}
          target="_blank"
        >
          {ticketId}
        </Link>
      )
    },
  },
  {
    id: 'transDate',
    label: `inventory.field.transDate`,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'ticketType',
    label: `field.type`,
    render: ({ row: { ticketType } }) => {
      return message({ id: `ticketType.${ticketType}` })
    },
  },
  {
    id: 'locationName',
    label: `inventory.field.location`,
  },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
    render: ({ row: { quantity } }) =>
      quantity >= 0 ? quantity : <Text color="error.1">{quantity}</Text>,
  },
]

export const handlers = ({ setState, session, app, value, setPagination }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value || !value.productVariantId) return

    const resp = await getData({ session, app, value, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, value, pagination }) {
  const variables = { input: getDataInput({ pagination, value }) }
  const query = `
    query($input: InventoryQueryInput!) {
      inventoryLinkCount(input: $input)
      inventoryLinks(input: $input) {
        ticketId
        ticketType
        locationName
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryLinks,
    pagination: getPagination(page, countPerPage, data.inventoryLinkCount),
  }
}

function getDataInput({ pagination, value }) {
  const input = {
    productVariantId: value.productVariantId,
    orderBy: [{ key: 'createdAt', sort: 'DESC' }],
  }
  if (value.locationId) input.locationId = value.locationId
  if (value.locationType) input.locationType = value.locationType
  if (pagination) input.limit = getLimit(pagination)
  return input
}
