import React from 'react'
import { Box } from 'reflexbox'
import { Center, Section } from 'components/core'

export default ({ open, onClose, title, children, ...props }) => {
  // const handleClose = (e) => {
  //   if (!onClose) return
  //   if (e.target !== e.currentTarget) return
  //   onClose()
  // }

  return (
    <>
      <Box
        height="100vh"
        width="100vw"
        bg="dark.3"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          transform: open ? 'scale(1)' : 'scale(0)',
          opacity: open ? 0.6 : 0,
          transition: 'opacity 0.15s ease-out',
        }}
      />
      <Center
        // onClick={handleClose}
        height="100vh"
        width="100vw"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1001,
          transform: open ? 'translateY(0px)' : 'translateY(110vh)',
          opacity: open ? 1 : 0,
          transition: `transform .3s ease-out, opacity 0.15s ease-out`,
          overflowY: 'auto',
        }}
      >
        <Section
          headerProps={{ display: 'flex' }}
          title={title}
          titleProps={{ flex: 1 }}
          {...props}
        >
          {children}
        </Section>
      </Center>
    </>
  )
}
