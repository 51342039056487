import React, { useReducer } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'emotion-theming'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { IntlProvider } from 'react-intl'
import zjTW from 'messages/zh-TW'
import { RootRoute } from 'configs/route'
import theme from 'configs/theme'
import { SessionContext, AppContext } from 'contexts'
import * as root from 'reducers/session'
import * as app from 'reducers/app'
import { LoadingBar, Notification, ConfirmModal } from 'components/core'
import * as serviceWorker from './serviceWorker'

const rootDiv = document.getElementById('root')
const history = createBrowserHistory()
history.listen(() => {
  rootDiv.scrollTop = 0
})

const Root = () => {
  const [rootState, rootDispatch] = useReducer(root.reducer, root.initialState)
  const [appState, appDispatch] = useReducer(app.reducer, app.initialState)

  return (
    <IntlProvider locale="zh" messages={zjTW}>
      <ThemeProvider theme={theme}>
        <DndProvider backend={Backend}>
          <SessionContext.Provider
            value={{ state: rootState, dispatch: rootDispatch }}
          >
            <AppContext.Provider
              value={{ state: appState, dispatch: appDispatch }}
            >
              <Router history={history}>
                <RootRoute />
              </Router>
              <LoadingBar loading={rootState.loading} />
              <Notification values={rootState.alerts} />
              <ConfirmModal value={rootState.ConfirmModal} />
            </AppContext.Provider>
          </SessionContext.Provider>
        </DndProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

ReactDOM.render(<Root />, rootDiv)
serviceWorker.unregister()
