export default {
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fontSizes: [12, 14, 18, 20, 24, 32, 48, 64],
  colors: {
    primary: ['#456FBB', '#2455AE', '#0D3B90'],
    secondary: ['#678CD1', '#436EBD', '#2253AD'],
    accent: ['#b761c5', '#9c27b0', '#721d81'],
    success: ['#7cc47f', '#4caf50', '#38803b'],
    warning: ['#ffb445', '#ff9800', '#ba6f00'],
    error: ['#f7766c', '#f44336', '#b23128'],
    light: ['#fff', '#f9f9f9', '#efefef', '#d0d0d0'],
    dark: ['#8c8c8c', '#666', '#444', '#000'],
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    link: {
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        color: 'secondary.1',
      },
    },
    disabledLink: {
      cursor: 'not-allowed',
      userSelect: 'none',
      color: 'dark.0',
    },
    primaryLink: {
      color: 'secondary.1',
      cursor: 'pointer',
      userSelect: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: 'accent.1',
      },
    },
    invertLink: {
      py: 2,
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        color: 'light.0',
      },
    },
    navbar: {
      bg: 'light.0',
      color: 'dark.2',
      boxShadow: 'small',
    },
    toolbar: {
      bg: 'light.2',
      color: 'dark.2',
    },
    navLink: {
      color: 'light.3',
      borderRadius: '3px',
      transition: 'color 300ms linear, background-color 300ms linear',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        color: 'light.0',
        bg: 'rgba(200, 200, 200, 0.2)',
      },
    },
    navGroupActive: {
      color: 'light.0',
      bg: 'rgba(200, 200, 200, 0.2)',
      borderRadius: '3px',
      transition: 'color 300ms linear, background-color 300ms linear',
      cursor: 'pointer',
      userSelect: 'none',
    },
    navLinkActive: {
      color: 'light.0',
      bg: 'secondary.1',
      borderRadius: '3px',
      transition: 'color 300ms linear, background-color 300ms linear',
      cursor: 'pointer',
      userSelect: 'none',
      boxShadow: `0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12)`,
    },
    primary: {
      color: 'light.0',
      background: 'linear-gradient(60deg, #678CD1, #436EBD)',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(34, 83, 173, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(34, 83, 173, 0.2)`,
    },
    success: {
      color: 'light.0',
      bg: 'success.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)`,
    },
    warning: {
      color: 'light.0',
      bg: 'warning.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(186, 111, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(186, 111, 0, 0.2)`,
    },
    error: {
      color: 'light.0',
      bg: 'error.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)`,
    },
  },
  text: {
    heading: {
      fontSize: 2,
      fontWeight: 300,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textShadow: '0px 1px 0px #999',
    },
  },
  buttons: {
    primary: {
      py: 2,
      px: 3,
      lineHeight: 1,
      fontSize: 1,
      color: 'accent.1',
      bg: 'light.0',
      boxShadow: `2px 3px 0px 0px #9c27b0`,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'accent.1',
      borderRadius: '3px',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: 'light.0',
        background: 'linear-gradient(60deg, #b761c5, #9c27b0)',
        boxShadow: `2px 3px 0px 0px #721d81`,
      },
      '&:active': {
        position: 'relative',
        top: '1px',
      },
      '&:disabled': {
        color: 'dark.0',
        borderColor: 'light.3',
        bg: 'light.0',
        backgroundImage: 'none',
        boxShadow: `2px 3px 0px 0px #d0d0d0`,
        cursor: 'not-allowed',
      },
    },
    secondary: {
      py: 2,
      px: 3,
      lineHeight: 1,
      fontSize: 1,
      color: 'dark.2',
      bg: 'light.0',
      boxShadow: `2px 3px 0px 0px #666`,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'dark.1',
      borderRadius: '3px',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: 'secondary.2',
        borderColor: 'secondary.1',
        boxShadow: `2px 3px 0px 0px #436EBD`,
      },
      '&:active': {
        position: 'relative',
        top: '1px',
      },
      '&:disabled': {
        color: 'dark.0',
        borderColor: 'light.3',
        boxShadow: `2px 3px 0px 0px #d0d0d0`,
        cursor: 'not-allowed',
      },
    },
    default: {
      py: 2,
      px: 2,
      lineHeight: 1,
      fontSize: 1,
      color: 'secondary.1',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: 'accent.1',
      },
      '&:disabled': {
        color: 'dark.0',
        cursor: 'not-allowed',
      },
    },
    icon: {
      p: 0,
      color: 'dark.1',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `secondary.1`,
      },
      '&:disabled': {
        color: 'dark.0',
        cursor: 'not-allowed',
      },
    },
  },
}
