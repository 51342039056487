import React from 'react'
import { request } from 'utilities/request'
import { Text } from 'components/core'

export const columns = [
  {
    id: 'locationName',
    label: `inventory.field.technician`,
  },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
    render: ({ row: { quantity } }) =>
      quantity >= 0 ? quantity : <Text color="error.1">{quantity}</Text>,
  },
]

export const handlers = ({ setState, session, app, value }) => ({
  handleLoad: async () => {
    if (!value || !value.productVariantId) return

    const data = await getData({ session, app, value })
    setState(data)
  },
})

async function getData({ session, app, value }) {
  const variables = { input: getDataInput({ value }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryPartList(input: $input) {
        locationName
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.inventoryPartList
}

function getDataInput({ value }) {
  return {
    productVariantId: value.productVariantId,
    locationType: 'TECHNICIAN',
    orderBy: [{ key: 'locationName' }],
  }
}
