import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  validateForm,
  getSelectOptions,
  getSelectOption,
  getDate,
  renderAttach,
  renderDateInput,
  renderSelectInput,
  renderTextInput,
  renderTextAreaInput,
  renderTable,
  renderDefinition,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Select, NumberInput } from 'components/form'
import { printHtml } from 'utilities/print'
import { getInitialItemInput, handleDelete } from 'actions/ticket'
import { getPartOptions, getSkuOptions } from 'actions/part'
import { uploadFile } from 'utilities/file'

const url = process.env.REACT_APP_STATIC_URL

export const initialState = (value = {}) => {
  const warehouses = getSelectOptions(value.warehouses)
  return {
    id: value.id,
    status: value.status || 'PENDING',
    hash: value.hash,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    inputValues: getInitialItemInput(),
    parts: value.parts || [],
    partOptions: getPartOptions(value.parts),
    skuOptions: getSkuOptions(value.parts),
    warehouses,
    warehouseName: value.warehouseName || '',
    attach: value.attach,
    attachToAdd: null,
    attachToDel: null,
    ...initializeState({
      transDate: getDate(value.transDate),
      warehouseId: getSelectOption(warehouses, value.warehouseId),
      companyName: value.companyName || '',
      packingNo: value.packingNo || '',
      boxNo: value.boxNo || '',
      memo: value.memo || '',
    }),
  }
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

// const defs = [
//   { id: 'id', label: 'field.ticketId' },
//   { id: 'transDate', label: 'field.date' },
//   {
//     id: 'warehouseId',
//     label: 'field.warehouse',
//     render: (state) => state.warehouseName,
//   },
//   { id: 'companyName', label: 'buy.field.companyName' },
//   { id: 'packingNo', label: 'buy.field.packingNo' },
//   { id: 'boxNo', label: 'buy.field.boxNo' },
//   { id: 'memo', label: 'field.memo' },
//   { id: 'attach', label: 'buy.field.attach' },
// ]

// const columns = [
//   { id: 'productVariantName', label: 'field.partName' },
//   { id: 'sku', label: 'field.sku' },
//   {
//     id: 'location',
//     label: 'part.field.location',
//     render: ({ row }) => row.extra?.location,
//   },
//   {
//     id: 'unitPrice',
//     label: 'field.unitPrice',
//     align: 'right',
//     render: ({ row }) => row.extra?.unitPrice,
//   },
//   { id: 'quantity', label: 'field.quantity', align: 'right' },
// ]

export const fields = ({ app, session, state, setState, profile, format }) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName: 'buy',
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    transDate: dateInput({ id: 'transDate' }),
    warehouseId: selectInput({
      id: 'warehouseId',
      valKey: 'warehouseName',
      isClearable: false,
      options: state.warehouses,
    }),
    companyName: textInput({ id: 'companyName' }),
    packingNo: textInput({ id: 'packingNo' }),
    boxNo: textInput({ id: 'boxNo' }),
    memo: textAreaInput({ id: 'memo' }),
    attach: renderAttach({
      ...commonProps,
      session,
      id: 'attach',
      accept: '.pdf',
      href: `${url}/${app.state.staff.merchantId}/${state.id}/${state.attach}`,
    }),
    ticketItems: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'field.partName',
          renderInput: () => (
            <Select
              isClearable={false}
              options={state.partOptions}
              onChange={({ value }) => {
                const part = state.parts.find(({ id }) => id === value)
                return { sku: { value: part.id, label: part.sku } }
              }}
            />
          ),
        },
        {
          id: 'sku',
          label: 'field.sku',
          renderInput: () => (
            <Select
              isClearable={false}
              options={state.skuOptions}
              onChange={({ value }) => {
                const part = state.parts.find(({ id }) => id === value)
                const result = { value: part.id, label: part.name }
                return { productVariantName: result }
              }}
            />
          ),
        },
        {
          id: 'location',
          label: 'part.field.location',
          profile: ['view'],
          render: ({ row }) => row.extra?.location,
        },
        {
          id: 'unitPrice',
          label: 'field.unitPrice',
          align: 'right',
          profile: ['view'],
          render: ({ row }) => row.extra?.unitPrice,
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: '96px',
          align: 'right',
          renderInput: () => <NumberInput min="1" />,
          defaultInputValue: 1,
        },
      ],
      rows: state.ticketItems,
      showAddInput: profile !== 'view',
      showDeleteIcon: profile !== 'view',
      inputValues: state.inputValues,
      onInputChange: (value) => setState({ ...state, inputValues: value }),
      onAdd: ({ row }) => {
        const { sku, productVariantName, quantity } = row
        if (!productVariantName.value) return

        const ticketItems = cloneDeep(state.ticketItems)
        const part = state.parts.find((item) => item.id === sku.value)
        const unitPrice = parseFloat(part.price)
        ticketItems.push({
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          sku: sku.label,
          quantity: parseInt(quantity),
          price: unitPrice * quantity,
          extra: { location: part.extra?.location, unitPrice },
        })
        const inputValues = getInitialItemInput()
        setState({ ...state, ticketItems, inputValues })
      },
      onDelete: ({ index }) => {
        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.splice(index, 1)
        setState({ ...state, ticketItems })
      },
    }),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editPartBuy(state, app, session)
      : await addPartBuy(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartBuyTicket

    const uploadOk = await updateAttach(app, session, state, id)
    if (!uploadOk) {
      if (!id) history.push(`/buy/${id}/edit`)
      return
    }

    history.push(`/buy/${id}/view`)
  },
  handleScan: async (value) => {
    const part = state.parts.find(({ sku }) => sku === value)
    if (!part) {
      const errMessage = 'error.part.notFound'
      return { name: '', balance: 0, quantity: 0, errMessage }
    }

    const ticketItemIdx = state.ticketItems.findIndex(
      (item) => item.productVariantId === part.id,
    )
    const ticketItem = state.ticketItems[ticketItemIdx]
    const quantity = ticketItem ? ticketItem.quantity + 1 : 1
    const ticketItems = cloneDeep(state.ticketItems || [])
    if (ticketItem) {
      ticketItem.quantity = quantity
      ticketItems.splice(ticketItemIdx, 1, ticketItem)
    } else {
      const unitPrice = parseFloat(part.price)
      ticketItems.push({
        productVariantId: part.id,
        productVariantName: part.name,
        sku: part.sku,
        quantity,
        price: unitPrice * quantity,
        extra: { location: part.extra?.location, unitPrice },
      })
    }
    setState({ ...state, ticketItems })
    return { name: part.name, quantity }
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partBuy', { session, app, id, hash })
    if (!ok) return false

    history.push('/buy')
    return true
  },
  handlePrint: () => {
    const title = 'buy.title.view'
    const form = fields({ format: 'print', app, state, message })
    const hidden = ['attach', 'ticketItems']
    const field = Object.values(form).filter(({ id }) => !hidden.includes(id))
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: form.ticketItems },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, id }) {
  const locationInput = { type: ['PART_WAREHOUSE'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      parts {
        id
        name
        sku
        price
        extra
      }
      partBuyTicket(id: $id) {
        toLocationId
        toLocationName
        transDate
        status
        hash
        extra
      }
      partBuyTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partBuyTicket || {}
  const ticketItems = data.partBuyTicketItems || []

  return {
    id,
    hash: ticket.hash,
    warehouseId: ticket.toLocationId,
    warehouseName: ticket.toLocationName,
    transDate: ticket.transDate,
    companyName: ticket.extra?.companyName,
    packingNo: ticket.extra?.packingNo,
    boxNo: ticket.extra?.boxNo,
    memo: ticket.extra?.memo,
    attach: ticket.extra?.attach,
    status: ticket.status,
    ticketItems,
    oldTicketItems: cloneDeep(ticketItems),
    warehouses: data.locations,
    parts: data.parts,
  }
}

async function addPartBuy(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartBuyTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editPartBuy(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartBuyTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { attach, hash, oldTicketItems } = state
  const isFile = attach instanceof File
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate,
    toLocationId: state.warehouseId.value,
    extra: {
      companyName: state.companyName,
      packingNo: state.packingNo,
      boxNo: state.boxNo,
      memo: state.memo,
      attach: isFile ? attach.name : attach,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function updateAttach(app, session, state, id) {
  const { attachToAdd, attachToDel } = state

  const result = await Promise.all([
    attachToDel ? deleteFile(app, session, id, attachToDel) : true,
    attachToAdd ? addFile(app, session, id, attachToAdd) : true,
  ])
  return !result.some((ok) => !ok)
}

async function addFile(app, session, id, file) {
  const variables = { id, filename: file.name, contentType: file.type }
  const query = `
    mutation($id: ID!, $filename: String!, $contentType: String!) {
      addFile(id: $id, filename: $filename, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addFile
  uploadFile({ url, fields, file })
  return ok
}

async function deleteFile(app, session, id, file) {
  const variables = { id, filename: file }
  const query = `
    mutation($id: ID!, $filename: String!) {
      deleteFile(id: $id, filename: $filename) 
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
