export default {
  'buy.title.list': '零件進倉目錄',
  'buy.title.add': '新增進倉單',
  'buy.title.edit': '修改進倉單',
  'buy.title.view': '進倉單詳情',
  'buy.section.basic': '基礎信息',
  'buy.section.part': '進倉零件',
  'buy.field.transDate': '日期',
  'buy.field.warehouseId': '倉庫',
  'buy.field.techId': '入庫倉別',
  'buy.field.packingNo': 'Packing No',
  'buy.field.boxNo': '箱號',
  'buy.field.companyName': '廠商名稱',
  'buy.field.memo': '備註',
  'buy.field.attach': '附件',
}
