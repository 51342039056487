import React from 'react'
import { getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { getSelectOptions, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { LinkButton, StatusChip, Link } from 'components/core'
import { MdEdit } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { FILTER_SET } from 'constants/actionType'

export const filters = (data) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'techId',
    label: 'field.technician',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.techs),
      }),
  },
  {
    id: 'warehouseId',
    label: 'field.warehouse',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.warehouses),
      }),
  },
  {
    id: 'productVariantName',
    label: 'field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'field.sku',
    input: textFilter,
  },
]

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Link variant="primaryLink" href={`/checkout/${row.id}/view`}>
        {row.id}
      </Link>
    ),
  },
  {
    id: 'transDate',
    label: 'field.date',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'fromLocationName',
    label: 'field.warehouse',
    format: ['html', 'excel'],
  },
  {
    id: 'toLocationName',
    label: 'field.technician',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.partName',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['excel'],
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    renderHtml: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/checkout/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'checkout',
          session,
          status: row.status,
          onSubmit: () =>
            handleDelete('partCheckout', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
  setTechs,
  setWarehouses,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'checkoutList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setTechs(resp.techs)
    setWarehouses(resp.warehouses)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handlerExport: async ({ filterValues, createExcel }) => {
    const title = 'checkout.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['TECHNICIAN', 'PART_WAREHOUSE'] },
    input: getDataInput({ pagination, filterValues }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      partCheckoutTicketCount(input: $input)
      partCheckoutTickets(input: $input) {
        id
        ticketNo
        fromLocationName
        toLocationName
        transDate
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination

  const techs = []
  const warehouses = []
  data.locations.forEach((item) => {
    switch (item.type) {
      case 'TECHNICIAN':
        techs.push(item)
        break
      case 'PART_WAREHOUSE':
        warehouses.push(item)
        break
      default:
    }
  })

  return {
    techs,
    warehouses,
    data: data.partCheckoutTickets,
    pagination: getPagination(page, countPerPage, data.partCheckoutTicketCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      partCheckoutTickets(input: $input) {
        id
        ticketNo
        fromLocationName
        toLocationName
        productVariantName
        sku
        quantity
        extra
        transDate
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.partCheckoutTickets.map((item) => {
    const { extra } = item
    item.billingName = extra.billCompany?.name
    item.memo = extra.memo
    return item
  })
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const techId = filterValues.find(({ id }) => id === 'techId')
  const warehouseId = filterValues.find(({ id }) => id === 'warehouseId')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const productName = filterValues.find(({ id }) => id === 'productVariantName')
  const input = {
    joinItem,
    id: id?.value,
    transDate: transDate?.value,
    fromLocationId: warehouseId?.value.value,
    toLocationId: techId?.value.value,
    sku: sku?.value,
    productVariantName: productName?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
