import React from 'react'
import { getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { showDate } from 'utilities/form'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { LinkButton, StatusChip, Link } from 'components/core'
import { MdEdit } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { FILTER_SET } from 'constants/actionType'

export const filters = [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'toLocationName',
    label: 'buy.field.warehouseId',
    input: textFilter,
  },
  {
    id: 'partName',
    label: 'field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'field.sku',
    input: textFilter,
  },
  {
    id: 'companyName',
    label: 'buy.field.companyName',
    input: textFilter,
  },
  {
    id: 'device',
    label: 'part.field.device',
    input: textFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
]

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Link variant="primaryLink" href={`/buy/${row.id}/view`}>
        {row.id}
      </Link>
    ),
  },
  {
    id: 'transDate',
    label: 'field.date',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'toLocationName',
    label: 'buy.field.warehouseId',
    format: ['html', 'excel'],
  },
  {
    id: 'companyName',
    label: 'buy.field.companyName',
    format: ['html', 'excel'],
    render: ({ row }) => row.extra?.companyName,
  },
  {
    id: 'packingNo',
    label: 'buy.field.packingNo',
    format: ['html', 'excel'],
    render: ({ row }) => row.extra?.packingNo,
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.partName',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['excel'],
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    renderHtml: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/buy/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'buy',
          session,
          status: row.status,
          onSubmit: () =>
            handleDelete('partBuy', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'buyList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'buy.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: TicketQueryInput) {
      partBuyTicketCount(input: $input)
      partBuyTickets(input: $input) {
        id
        ticketNo
        toLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.partBuyTickets,
    pagination: getPagination(page, countPerPage, data.partBuyTicketCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      partBuyTickets(input: $input) {
        id
        ticketNo
        toLocationName
        productVariantName
        sku
        quantity
        extra
        transDate
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.partBuyTickets.map((item) => {
    const { extra } = item
    item.billingName = extra.billCompany?.name
    item.memo = extra.memo
    return item
  })
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const toLocationName = filterValues.find(({ id }) => id === 'toLocationName')
  const partName = filterValues.find(({ id }) => id === 'partName')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const companyName = filterValues.find(({ id }) => id === 'companyName')
  const device = filterValues.find(({ id }) => id === 'device')
  const input = {
    joinItem,
    id: id?.value,
    transDate: transDate?.value,
    toLocationName: toLocationName?.value,
    productVariantName: partName?.value,
    sku: sku?.value,
    extra: {
      companyName: companyName?.value,
    },
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (device) {
    input.product = {
      device: device?.value,
    }
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
