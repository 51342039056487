import React, { useEffect, useContext } from 'react'
import { navLinks } from 'configs/sidebar'
import { profile } from 'configs/header'
import { isActive } from 'utilities/list'
import { refreshToken } from 'utilities/request'
import { AppContext, SessionContext } from 'contexts'
import { useHistory } from 'react-router-dom'
import {
  NAV_SET,
  NAV_TOGGLE,
  REDIRECT_SET,
  TOKEN_SET,
} from 'constants/actionType'
import { Box } from 'reflexbox'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  Link,
  Popover,
  Overlay,
  Image,
  Divider,
  List,
  ListGroup,
  ListItem,
  Navbar,
  Grid,
  Button,
  Center,
} from 'components/core'
import { AppRoute } from 'configs/route'
import Logo from 'assets/svg/Logo'
import AvatarImg from 'assets/img/01.png'
import bgImg from 'assets/img/background.jpg'
import { MdMenu, MdClose, MdApps } from 'react-icons/md'
// import { subscribe, unsubscribeAll } from 'utilities/graphql'
// import gql from 'graphql-tag'

const sidebarWidth = 260

const Brand = ({ onClose }) => (
  <Link
    display="flex"
    icon={<Logo color="#fff" />}
    text="app.repair"
    textProps={{ flex: 1, color: 'light.0', ml: 3, fontSize: 2 }}
    rightIcon={
      <Button
        display={['flex', 'none']}
        variant="icon"
        icon={<MdClose />}
        color="light.2"
        fontSize={2}
        onClick={onClose}
      />
    }
    px={2}
    py={0}
    mb={3}
    href="/"
  />
)

const NavList = ({ history, items, navId, setNavId }) => {
  if (!items) return null

  const listItems = items.filter(({ show = true, path, items }) => {
    if (!show) return false

    if (!path) {
      const subItems = items.filter(({ show }) => show)
      if (subItems.length === 0) return false
    }

    return true
  })
  if (listItems.length === 0) return null

  return (
    <List>
      {listItems.map((item) => {
        if (item.items) {
          const active = isActive(item.items, navId)
          return (
            <ListGroup
              key={item.id}
              p={2}
              mt={2}
              icon={item.icon}
              label={item.label}
              active={active}
              variant={active ? 'navGroupActive' : 'navLink'}
            >
              <NavList
                history={history}
                items={item.items}
                navId={navId}
                setNavId={setNavId}
              />
            </ListGroup>
          )
        }

        const active = item.id === navId
        return (
          <ListItem
            key={item.id}
            height="36px"
            p={2}
            mt={2}
            label={item.label}
            icon={item.icon || <Box width="30px" />}
            active={active}
            variant={active ? 'navLinkActive' : 'navLink'}
            onClick={() => {
              setNavId(item.id)
              history.push(item.path)
            }}
          />
        )
      })}
    </List>
  )
}

export default ({ match }) => {
  const url = new URL(window.location.href)
  const history = useHistory()
  const app = useContext(AppContext)
  const session = useContext(SessionContext)
  const { navId, navOpen, token, staff } = app.state

  useEffect(() => {
    if (!isLatestVersion()) {
      clearCache()
    }
  }, [])

  useEffect(() => {
    refreshToken(token, session, app).then((token) => {
      app.dispatch({ type: TOKEN_SET, token })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!token.accessToken) {
      session.dispatch({
        type: REDIRECT_SET,
        item: url.pathname + url.search,
      })
      history.push('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.accessToken])

  // useEffect(() => {
  //   subscribe({
  //     query: gql`
  //       subscription somethingChanged {
  //         somethingChanged
  //       }
  //     `,
  //     next(data) {
  //       console.log('subscription triggered!', data)
  //     },
  //   })
  //   return unsubscribeAll
  // }, [])

  return (
    <>
      <Box
        width={navOpen ? sidebarWidth : 0}
        height="100%"
        bg="dark.0"
        sx={{
          position: 'fixed',
          zIndex: 101,
          top: 0,
          left: 0,
          overflowX: 'hidden',
          transition: 'width 200ms ease',
        }}
      >
        <Overlay
          bgImage={bgImg}
          bgSx={{ backgroundPosition: 'left center' }}
          contentSx={{ width: sidebarWidth }}
        >
          <Scrollbars>
            <Box p={3}>
              <Brand
                onClose={(event) => {
                  event.stopPropagation()
                  app.dispatch({ type: NAV_TOGGLE })
                }}
              />
              <Divider />
              <NavList
                history={history}
                items={navLinks(staff)}
                navId={navId}
                setNavId={(id) => app.dispatch({ type: NAV_SET, id })}
              />
            </Box>
          </Scrollbars>
        </Overlay>
      </Box>
      <Box
        height="100%"
        ml={[0, navOpen ? sidebarWidth : 0]}
        bg="light.2"
        sx={{
          transition: 'margin-left 200ms ease',
        }}
      >
        <Grid rows="64px 1fr" height="100%">
          <Navbar
            px={[3, 4]}
            sx={{ zIndex: 100 }}
            variant="navbar"
            menu={
              <Button
                variant="icon"
                icon={<MdMenu />}
                iconProps={{ fontSize: 4 }}
                onClick={() => app.dispatch({ type: NAV_TOGGLE })}
              />
            }
            action={
              <Center>
                <Popover
                  mr={3}
                  contentProps={{ mt: '40px' }}
                  linkProps={{ mx: 0 }}
                  icon={<MdApps />}
                >
                  <Link
                    py={2}
                    variant="primaryLink"
                    text="app.stock"
                    href={process.env.REACT_APP_STOCK_URL}
                    target="_blank"
                  />
                  <Link
                    py={2}
                    variant="primaryLink"
                    text="app.repair"
                    disabled
                  />
                </Popover>
                <Popover
                  contentProps={{ mt: '40px' }}
                  linkProps={{ mx: 0 }}
                  icon={<Image src={AvatarImg} height="30px" width="30px" />}
                >
                  {profile.map((item) => (
                    <Link
                      key={item.id}
                      py={2}
                      variant="primaryLink"
                      icon={item.icon}
                      iconProps={{ mr: 1 }}
                      text={item.label}
                      href={item.path}
                      onClick={
                        item.onClick && item.onClick({ app, session, history })
                      }
                    />
                  ))}
                </Popover>
              </Center>
            }
          />
          <AppRoute />
        </Grid>
      </Box>
      <iframe
        id="ticket-default"
        title="ticket-default"
        style={{ display: 'none' }}
      ></iframe>
    </>
  )
}

async function isLatestVersion() {
  const resp = await fetch('/meta.json')
  const meta = await resp.json()
  const { version } = meta
  const { REACT_APP_VERSION } = process.env
  console.log('currentVersion', REACT_APP_VERSION)
  console.log('latestVersion', version)
  return version === REACT_APP_VERSION
}

async function clearCache() {
  const cacheKeys = await caches.keys()
  if (cacheKeys.length === 0) return

  cacheKeys.forEach((key) => caches.delete(key))
  window.location.reload()
}
