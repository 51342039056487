import React from 'react'
import { request } from 'utilities/request'
import { getLimit, getPagination } from 'utilities/pagination'
import { textFilter, selectFilter } from 'utilities/filter'
import { getSelectOptions } from 'utilities/form'
import { Link } from 'components/core'
import { printHtml } from 'utilities/print'

export const filters = (data) => [
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter,
  },
  {
    id: 'barcode',
    label: 'part.field.barcode',
    input: textFilter,
  },
  {
    id: 'warehouse',
    label: 'field.warehouse',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.warehouses,
      }),
  },
  {
    id: 'productLocation',
    label: 'part.field.location',
    input: textFilter,
  },
]

export const columns = ({ action }) => [
  {
    id: 'locationName',
    label: 'field.warehouse',
  },
  {
    id: 'productVariantName',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'barcode',
    label: 'part.field.barcode',
  },
  {
    id: 'productLocation',
    label: 'part.field.location',
  },
  {
    id: 'price',
    label: 'part.field.price',
    align: 'right',
  },
  // {
  //   id: 'averageQuantity',
  //   label: 'part.field.monthlyAvgQuantity',
  //   align: 'right',
  // },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleOpen({
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.quantity}
      </Link>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  state,
  setState,
  setFilterValues,
  setPagination,
  setWarehouses,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setWarehouses(resp.warehouses)
    setPagination(resp.pagination)
  },
  handlePrint: async ({ filterValues }) => {
    const rows = await getExportData({ session, app, filterValues })
    const title = 'inventory.title.warehouseList'
    const list = { columns: columns({ message }), rows }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'inventory.title.warehouseList'
    const cols = columns({ message })
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, filterValues = [], pagination }) {
  const variables = {
    locationInput: { type: ['PART_WAREHOUSE'] },
    input: getDataInput({ pagination, filterValues }),
  }
  const query = `
    query($input: InventoryQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      inventoryPartCount(input: $input)
      inventoryPartList(input: $input) {
        locationId
        locationName
        productVariantId
        productVariantName
        sku
        barcode
        productLocation
        quantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination || {}
  return {
    data: data.inventoryPartList,
    pagination: getPagination(page, countPerPage, data.inventoryPartCount),
    warehouses: getSelectOptions(data.locations),
  }
}

async function getExportData({ session, app, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryPartList(input: $input) {
        locationId
        locationName
        productVariantId
        productVariantName
        sku
        productLocation
        quantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.inventoryPartList
}

function getDataInput({ filterValues, pagination }) {
  const warehouse = filterValues.find(({ id }) => id === 'warehouse')
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const barcode = filterValues.find(({ id }) => id === 'barcode')
  const productLocation = filterValues.find(
    ({ id }) => id === 'productLocation',
  )
  const input = {
    locationType: 'PART_WAREHOUSE',
    locationId: warehouse?.value.value,
    productVariantName: name?.value,
    productLocation: productLocation?.value,
    sku: sku?.value,
    barcode: barcode?.value,
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
