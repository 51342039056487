import React from 'react'
import { Button, Link, Message } from 'components/core'
import { CONFIRM_SET } from 'constants/actionType'
import { MdDelete } from 'react-icons/md'

export const renderMessage = (text, textProps = {}) => {
  if (typeof text === 'string') {
    return <Message id={text} {...textProps} />
  }
  return <Message {...text} {...textProps} />
}

export const getMessage = (formatter, message) => {
  if (typeof message === 'string') {
    return formatter({ id: message })
  }

  const { id, values, texts } = message
  let params = values || {}

  if (texts) {
    Object.entries(texts).forEach(([key, val]) => {
      params[key] = formatter({ id: val })
    })
  }

  return formatter({ id }, params)
}

export const renderConfirmButton = ({
  module,
  profile = 'view',
  status = 'PENDING',
  session,
  onSubmit,
}) => {
  const name = `module.${module}`
  return (
    <Button
      ml={3}
      show={profile === 'view'}
      disabled={['INACTIVE', 'ACTIVE', 'CALLBACK_PENDING'].includes(status)}
      variant="primary"
      text="btn.approve"
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'title.approve', texts: { name } },
          text: { id: 'message.approve', texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}

export const renderDeleteButton = ({
  module,
  status = 'PENDING',
  session,
  onSubmit,
}) => {
  const name = `module.${module.toLowerCase()}`
  return (
    <Button
      disabled={status === 'INACTIVE'}
      variant="icon"
      icon={<MdDelete />}
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'title.delete', texts: { name } },
          text: { id: 'message.delete', texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}

export const renderDeleteLink = ({
  module,
  readonly = false,
  status = 'PENDING',
  session,
  onSubmit,
}) => {
  const name = `module.${module}`
  return (
    <Link
      ml={3}
      show={readonly}
      disabled={['INACTIVE'].includes(status)}
      variant="link"
      icon={<MdDelete />}
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'title.delete', texts: { name } },
          text: { id: 'message.delete', texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}
