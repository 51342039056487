import React from 'react'
import { MdExitToApp, MdSecurity } from 'react-icons/md'
import { handleLogout } from 'models/logout'

export const profile = [
  {
    id: 'password',
    label: 'app.password',
    icon: <MdSecurity />,
    path: '/password',
  },
  {
    id: 'logout',
    label: 'app.logout',
    icon: <MdExitToApp />,
    onClick: ({ app, session, history }) => () =>
      handleLogout({ app, session, history }),
  },
]
