import React from 'react'
import { Box } from 'reflexbox'
import { Message } from 'components/core'

export default ({ id, label, errMsg, children, ...props }) => {
  if (errMsg && typeof errMsg === 'string') {
    errMsg = { id: errMsg }
  }

  if (errMsg && errMsg.constructor === Array) {
    errMsg = { id: errMsg[0], values: errMsg[1] }
  }

  return (
    <Box mt={3} {...props}>
      {label && (
        <Message
          as="label"
          htmlFor={id}
          display="block"
          color="dark.0"
          mb={1}
          id={label}
        />
      )}
      {children}
      {errMsg && (
        <Message
          display="block"
          mt={1}
          color="error.1"
          id={errMsg.id}
          values={errMsg.values}
        />
      )}
    </Box>
  )
}
