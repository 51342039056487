import React from 'react'
import { Box } from 'reflexbox'
import Navbar from './Navbar'

export default ({
  show = true,
  title,
  titleProps,
  action,
  actionProps,
  headerProps,
  children,
  contentProps,
  ...props
}) => {
  if (!show) return null
  const showHeader = title || action

  const renderHeader = () => (
    <Box width={1} px={3} sx={{ position: 'absolute', top: '-16px' }}>
      <Navbar
        display="inline-flex"
        title={title}
        titleProps={titleProps}
        action={action}
        actionProps={actionProps}
        {...headerProps}
      />
    </Box>
  )

  return (
    <Box
      minWidth={['320px', 'auto']}
      bg="light.0"
      sx={{ position: 'relative', boxShadow: 'small', borderRadius: '3px' }}
      {...props}
    >
      {showHeader && renderHeader()}
      <Box px={3} pt={showHeader ? 4 : 3} pb={3} {...contentProps}>
        {children}
      </Box>
    </Box>
  )
}
