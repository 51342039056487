import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { createExcel } from 'utilities/excel'
import { SessionContext, AppContext } from 'contexts'
import { columns, filters, handlers } from 'models/inventoryTechList'
import { Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import InventoryDrawer from 'views/InventoryDrawer'
import { initialPage } from 'utilities/pagination'
import { MdFileUpload, MdPrint } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [techs, setTechs] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState([])
  const { handleLoad, handlePrint, handleExport } = handlers({
    session,
    app,
    state,
    message,
    setState,
    setFilterValues,
    setPagination,
    setTechs,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <Page
      title={`inventory.title.techList`}
      navId={`inventoryTechList`}
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
          <Link
            variant="link"
            icon={<MdPrint />}
            text="btn.print"
            onClick={() => handlePrint({ filterValues })}
          />
        </Center>
      }
    >
      <Section>
        <Table
          showSeq
          columns={columns({ action: { handleOpen } })}
          rows={state}
          filters={filters({ techs })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <InventoryDrawer value={value} open={open} action={{ handleClose }} />
    </Page>
  )
}
