import React, { useContext, forwardRef } from 'react'
import { AppContext } from 'contexts'
import TextInput from './TextInput'
import { formatDate } from 'utilities/date'

export default forwardRef(({ id, min, max, role, value, ...props }, ref) => {
  const app = useContext(AppContext)
  if (app.state.staff?.staffType === 'OWNER') {
    min = '1970-01-01'
  }

  if (role && app.state.staff?.permissions.includes(role)) {
    min = '1970-01-01'
  }

  return (
    <TextInput
      ref={ref}
      type="date"
      min={min || getMinDate()}
      max={max || '9999-12-31'}
      value={value || ''}
      {...props}
    />
  )
})

function getMinDate() {
  const newDate = new Date()
  newDate.setDate(newDate.getDate() - 7)
  return formatDate(newDate)
}
