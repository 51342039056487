import React from 'react'
import { Switch, TextInput } from 'components/form'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { Definition } from 'components/core'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    name: value.name || '',
    isActive: value.status === 'ACTIVE',
  })

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="technician.field.name"
        placeholder="technician.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="part.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editTechnician(state, app, session)
      : await addTechnician(state, app, session)

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  },
})

async function addTechnician(value, app, session) {
  const { name, isActive } = value
  const status = isActive ? 'ACTIVE' : 'INACTIVE'
  const variables = { input: { name, type: 'TECHNICIAN', status } }
  const query = `
    mutation($input: LocationInput!) {
      addTechnician(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editTechnician(value, app, session) {
  const { id, name, isActive } = value
  const status = isActive ? 'ACTIVE' : 'INACTIVE'
  const variables = { id, input: { name, status } }
  const query = `
    mutation($id: ID!, $input: LocationInput!) {
      editTechnician(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
