import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from 'reflexbox'
import { Link, Collapsible, Text } from 'components/core'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

export default ({
  label,
  icon,
  active,
  children,
  variant,
  formatMessage = true,
  textSx,
  ...props
}) => {
  const [open, setOpen] = useState(active)

  useEffect(() => {
    setOpen(active)
  }, [active])

  return (
    <Flex as="li" flexDirection="column">
      <Link
        display="flex"
        variant={variant}
        icon={icon}
        iconProps={{ justifyContent: 'center', mr: 3, width: '30px' }}
        rightIcon={open ? <MdArrowDropUp /> : <MdArrowDropDown />}
        rightIconProps={{ ml: 3 }}
        onClick={() => setOpen(!open)}
        {...props}
      >
        <Text
          flex="1"
          fontSize={1}
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            ...textSx,
          }}
        >
          {formatMessage ? <FormattedMessage id={label} /> : label}
        </Text>
      </Link>
      <Collapsible open={open}>{children}</Collapsible>
    </Flex>
  )
}
