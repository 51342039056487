import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { TextInput, NumberInput, Switch } from 'components/form'
import { Definition } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'
import ImageInput from 'components/file/ImageInput'
import { deleteImage, addImage } from 'utilities/file'

const url = process.env.REACT_APP_STATIC_URL

export const initialState = (value = {}) => ({
  id: value.id || '',
  image: getImage(value.id, value.extra?.image),
  imageAdded: false,
  imageDeleted: false,
  image2: getImage(value.id, value.extra?.image2),
  image2Added: false,
  image2Deleted: false,
  image3: getImage(value.id, value.extra?.image3),
  image3Added: false,
  image3Deleted: false,
  ...initializeState({
    name: value.name || '',
    sku: value.sku || '',
    barcode: value.barcode || '',
    price: value.price || 0,
    location: value.extra?.location || '',
    device: value.extra?.device || '',
    minQuantity: value.extra?.minQuantity || 0,
    isActive: value.status !== 'INACTIVE',
  }),
})

function getImage(partId, imageId) {
  if (!partId || !imageId) return ''

  return {
    id: imageId,
    filename: imageId,
    path: `${url}/1/${partId}/${imageId}`,
  }
}

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 45, message: ['error.maxLength', { val: 45 }] },
  ],
  sku: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 32, message: ['error.maxLength', { val: 25 }] },
  ],
}

export const fields = ({ state, setState, app, session }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    name: (
      <TextInput
        id="name"
        label="part.field.partName"
        placeholder="part.field.partName"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    sku: (
      <TextInput
        id="sku"
        label="part.field.sku"
        placeholder="part.field.sku"
        value={state.sku}
        onChange={onTextChange('sku')}
        errMsg={state.__error__.sku}
      />
    ),
    barcode: (
      <TextInput
        id="barcode"
        label="part.field.barcode"
        placeholder="part.field.barcode"
        value={state.barcode}
        onChange={onTextChange('barcode')}
        errMsg={state.__error__.barcode}
      />
    ),
    location: (
      <TextInput
        id="location"
        label="part.field.location"
        placeholder="part.field.location"
        value={state.location}
        onChange={onTextChange('location')}
        errMsg={state.__error__.location}
      />
    ),
    device: (
      <TextInput
        id="device"
        label="part.field.device"
        placeholder="part.field.device"
        value={state.device}
        onChange={onTextChange('device')}
        errMsg={state.__error__.device}
      />
    ),
    // minQuantity: (
    //   <NumberInput
    //     id="minQuantity"
    //     label="part.field.minQuantity"
    //     placeholder="part.field.minQuantity"
    //     min={0}
    //     value={state.minQuantity}
    //     onChange={onTextChange('minQuantity')}
    //     errMsg={state.__error__.minQuantity}
    //   />
    // ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="part.field.price"
        placeholder="part.field.price"
        min={0}
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    image: (
      <ImageInput
        id="image"
        label="part.field.image"
        value={state.image}
        onDrop={(image) => {
          setState({ ...state, image, imageAdded: true })
        }}
        onDelete={(file) => {
          const imageDeleted = file.preview ? '' : file.id
          setState({ ...state, image: '', imageDeleted })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
      />
    ),
    image2: (
      <ImageInput
        id="image2"
        label="part.field.image2"
        value={state.image2}
        onDrop={(image2) => {
          setState({ ...state, image2, image2Added: true })
        }}
        onDelete={(file) => {
          const image2Deleted = file.preview ? '' : file.id
          setState({ ...state, image2: '', image2Deleted })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
      />
    ),
    image3: (
      <ImageInput
        id="image3"
        label="part.field.image3"
        value={state.image3}
        onDrop={(image3) => {
          setState({ ...state, image3, image3Added: true })
        }}
        onDelete={(file) => {
          const image3Deleted = file.preview ? '' : file.id
          setState({ ...state, image3: '', image3Deleted })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="part.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ app, session, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    let { id } = state
    const [ok, data] = id
      ? await editPart({ state, app, session })
      : await addPart({ state, app, session })
    if (!ok) return false

    if (!id) id = data.addPart

    if (state.imageDeleted) {
      const imageOk = await deleteImage(app, session, id, state.image)
      if (!imageOk) return false
    }

    if (state.imageAdded) {
      const imageOk = await addImage(app, session, id, state.image)
      if (!imageOk) return false
    }

    if (state.image2Deleted) {
      const imageOk = await deleteImage(app, session, id, state.image2)
      if (!imageOk) return false
    }

    if (state.image2Added) {
      const imageOk = await addImage(app, session, id, state.image2)
      if (!imageOk) return false
    }

    if (state.image3Deleted) {
      const imageOk = await deleteImage(app, session, id, state.image3)
      if (!imageOk) return false
    }

    if (state.image3Added) {
      const imageOk = await addImage(app, session, id, state.image3)
      if (!imageOk) return false
    }

    action.handleLoad()
    action.handleClose()

    return true
  },
})

async function addPart({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { input }
  const query = `
    mutation($input: PartInput!) {
      addPart(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editPart({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: PartInput!) {
      editPart(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  return {
    spu: state.name,
    sku: state.sku,
    barcode: state.barcode,
    price: parseFloat(state.price),
    extra: {
      location: state.location,
      device: state.device,
      minQuantity: state.minQuantity,
      image: state.image.id || state.image.name,
      image2: state.image2.id || state.image2.name,
      image3: state.image3.id || state.image3.name,
    },
    status: state.isActive ? 'ACTIVE' : 'INACTIVE',
  }
}
