export default {
  'error.product.duplicate': '零件名稱已存在',
  'error.productVariant.duplicate': '品號已存在',
  'error.part.notFound': '查無此零件代碼',
  'part.btn.scan': '掃描零件',
  'part.title': '零件管理',
  'part.title.list': '零件目錄',
  'part.title.add': '新增零件',
  'part.title.edit': '更改零件',
  'part.title.image': '零件圖示',
  'part.field.partName': '零件名稱',
  'part.field.sku': '零件代碼',
  'part.field.barcode': '零件條碼',
  'part.field.quantity': '數量',
  'part.field.minQuantity': '最低存量',
  'part.field.monthlyAvgQuantity': '月平均銷量',
  'part.field.price': '單價',
  'part.field.location': '儲放位置',
  'part.field.device': '適用機型',
  'part.field.image': '圖示1',
  'part.field.image2': '圖示2',
  'part.field.image3': '圖示3',
  'part.field.isActive': '開啟',
  'part.field.scanner': '請掃描零件代碼...',
  'part.field.scanQuantity': '掃描數量',
  'part.field.totalQuantity': '總掃描數量',
  'part.field.stockStatus': '庫存狀態',
  'part.stockStatus': '庫存數量小於月平均銷量x{multiple}',
  'part.status.ACTIVE': '使用中',
  'part.status.INACTIVE': '已停用',
}
