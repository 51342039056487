import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  showDate,
  getSelectOption,
  handleTextChange,
  getDate,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Definition, Table } from 'components/core'
import { Select, NumberInput, TextArea, DateInput } from 'components/form'
import { printHtml } from 'utilities/print'
import {
  getInitialItemInput,
  handleDelete,
  handleItemAdd,
  handleItemDelete,
  setBalances,
} from 'actions/ticket'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { getPartOptions, getSkuOptions } from 'actions/part'

export const initialState = (value = {}) => ({
  id: value.id,
  status: value.status || 'PENDING',
  hash: value.hash,
  techName: value.fromLocationName || '',
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  inputValues: getInitialItemInput(),
  parts: value.parts || [],
  partOptions: getPartOptions(value.parts),
  skuOptions: getSkuOptions(value.parts),
  technicians: value.technicians || [],
  warehouses: value.warehouses || [],
  warehouseName: value.warehouseName || '',
  ...initializeState({
    transDate: getDate(value.transDate),
    warehouseId: getSelectOption(value.warehouses, value.warehouseId),
    techId: getSelectOption(value.technicians, value.fromLocationId),
    memo: value.memo || '',
  }),
})

const validation = {
  warehouseId: [{ type: 'required', message: 'error.required' }],
  techId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'id', label: 'field.ticketId' },
  {
    id: 'transDate',
    label: 'field.date',
    render: (state) => showDate(state.transDate),
  },
  {
    id: 'techId',
    label: 'field.technician',
    render: (state) => state.techName,
  },
  {
    id: 'warehouseId',
    label: 'field.warehouse',
    render: (state) => state.warehouseName,
  },
  {
    id: 'memo',
    label: 'field.memo',
    render: (state) => state.memo,
  },
]

const columns = [
  { id: 'productVariantName', label: 'field.partName' },
  { id: 'sku', label: 'field.sku' },
  {
    id: 'location',
    label: 'part.field.location',
    render: ({ row }) => row.extra?.location,
  },
  { id: 'quantity', label: 'field.quantity', align: 'right' },
  { id: 'price', label: 'field.subTotal', align: 'right' },
]

export const labels = ({ state }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.ticketItems = (
    <Table showSeq columns={columns} rows={state.ticketItems} />
  )
  return content
}

export const fields = ({ app, session, state, setState, profile }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="field.date"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    warehouseId: (
      <Select
        id="warehouseId"
        label="field.warehouse"
        placeholder="field.warehouse"
        isClearable={false}
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    techId: (
      <Select
        isClearable={false}
        id="techId"
        label="field.technician"
        placeholder="field.technician"
        options={state.technicians}
        value={state.techId}
        onChange={onSelectChange('techId', async ({ value }) => {
          const { ticketItems, oldTicketItems } = state
          const balances = await fetchBalances(
            app,
            session,
            'TECHNICIAN',
            value,
            ticketItems.map((item) => item.productVariantId),
            oldTicketItems,
          )
          setBalances(ticketItems, balances)
          const inputValues = getInitialItemInput()
          return { ticketItems, inputValues }
        })}
        errMsg={state.__error__.techId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    ticketItems: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.partOptions}
                onChange={async ({ value }) => {
                  const part = state.parts.find(({ id }) => id === value)
                  const unitPrice = part.price || 0
                  const balance = await fetchBalance(
                    app,
                    session,
                    'TECHNICIAN',
                    state.techId?.value,
                    value,
                    state.oldTicketItems,
                  )
                  return {
                    sku: { value: part.id, label: part.sku },
                    unitPrice,
                    balance,
                  }
                }}
              />
            ),
          },
          {
            id: 'sku',
            label: 'field.sku',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.skuOptions}
                onChange={async ({ value }) => {
                  const part = state.parts.find(({ id }) => id === value)
                  const unitPrice = part.price || 0
                  const balance = await fetchBalance(
                    app,
                    session,
                    'TECHNICIAN',
                    state.techId?.value,
                    value,
                    state.oldTicketItems,
                  )
                  const result = { value: part.id, label: part.name }
                  return { productVariantName: result, unitPrice, balance }
                }}
              />
            ),
          },
          {
            id: 'balance',
            label: 'checkin.field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '96px',
            align: 'right',
            renderInput: ({ row }) => (
              <NumberInput min="1" max={row?.balance} />
            ),
            defaultInputValue: 1,
          },
        ]}
        rows={state.ticketItems}
        showAddInput
        showDeleteIcon
        inputValues={state.inputValues}
        onInputChange={(value) => setState({ ...state, inputValues: value })}
        onAdd={({ row }) => handleItemAdd({ session, state, setState, row })}
        onDelete={({ index }) => handleItemDelete({ state, setState, index })}
      />
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  profile,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, profile, id })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editCheckin(state, app, session)
      : await addCheckin(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartCheckinTicket

    history.push(`/checkin/${id}/view`)
  },
  handleScan: async (value) => {
    const part = state.parts.find(({ sku }) => sku === value)
    if (!part) {
      const errMessage = 'error.part.notFound'
      return { name: '', balance: 0, quantity: 0, errMessage }
    }

    const balance = await fetchBalance(
      app,
      session,
      'TECHNICIAN',
      state.techId?.value,
      part.id,
      state.oldTicketItems,
    )
    const ticketItemIdx = state.ticketItems.findIndex(
      (item) => item.productVariantId === part.id,
    )
    const ticketItem = state.ticketItems[ticketItemIdx]
    const quantity = ticketItem ? ticketItem.quantity + 1 : 1
    if (quantity > balance) {
      const errMessage = 'error.inventoryBalance.insufficientQuantity'
      return {
        name: part.name,
        balance,
        quantity: ticketItem?.quantity || 0,
        errMessage,
      }
    }

    const ticketItems = cloneDeep(state.ticketItems || [])
    if (ticketItem) {
      ticketItem.quantity = quantity
      ticketItems.splice(ticketItemIdx, 1, ticketItem)
    } else {
      const unitPrice = parseFloat(part.price)
      ticketItems.push({
        productVariantId: part.id,
        productVariantName: part.name,
        sku: part.sku,
        balance,
        quantity,
        price: unitPrice * quantity,
        extra: { location: part.extra?.location },
      })
    }
    setState({ ...state, ticketItems })
    return { name: part.name, balance, quantity }
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partCheckin', { session, app, id, hash })
    if (!ok) return false

    history.push('/checkin')
    return true
  },
  handlePrint: () => {
    const title = 'checkin.title.view'
    const field = defs.map(({ id, label, render }) => {
      const value = render ? render(state) : state[id]
      return { label, value }
    })
    const list = { columns, rows: state.ticketItems }
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: list },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, profile, id }) {
  const locationInput = { type: ['TECHNICIAN', 'PART_WAREHOUSE'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      parts {
        id
        name
        sku
        price
        extra
      }
      partCheckinTicket(id: $id) {
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
      }
      partCheckinTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partCheckinTicket || {}
  const ticketItems = data.partCheckinTicketItems?.map((item) => ({
    ...item,
    location: item.extra?.location,
    price: item.price,
  }))
  const oldTicketItems = cloneDeep(ticketItems)
  const warehouses = []
  const technicians = []

  data.locations.forEach((item) => {
    const result = { value: item.id, label: item.name }
    switch (item.type) {
      case 'PART_WAREHOUSE':
        warehouses.push(result)
        break
      case 'TECHNICIAN':
        technicians.push(result)
        break
      default:
    }
  })

  if (profile === 'edit' && ticketItems.length > 0) {
    const balances = await fetchBalances(
      app,
      session,
      'TECHNICIAN',
      ticket.fromLocationId,
      ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
    )
    setBalances(ticketItems, balances)
  }

  return {
    id,
    hash: ticket.hash,
    warehouseId: ticket.toLocationId,
    warehouseName: ticket.toLocationName,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    transDate: ticket.transDate,
    memo: ticket.extra?.memo,
    status: ticket.status,
    ticketItems,
    oldTicketItems,
    parts: data.parts,
    warehouses,
    technicians,
  }
}

async function addCheckin(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartCheckinTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editCheckin(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartCheckinTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, memo, warehouseId, techId, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate,
    fromLocationId: techId.value,
    toLocationId: warehouseId.value,
    extra: { memo },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}
