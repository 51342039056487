import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { Button, StatusChip } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete, MdEdit } from 'react-icons/md'
import { getStatusColor } from 'constants/status'

export const columns = ({ setState, session, app, action }) => [
  {
    id: 'name',
    label: 'technician.field.name',
  },
  {
    id: 'status',
    label: 'field.status',
    render: ({ row }) => (
      <StatusChip
        label={`technician.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'technician.title.delete',
                text: 'technician.message.delete',
                onSubmit: handleDelete({
                  setState,
                  session,
                  app,
                  id: row.id,
                  action,
                }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ setState, session, app }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app })
    setState(data)
  },
})

async function getData({ session, app }) {
  const variables = {
    input: { type: ['TECHNICIAN'], status: ['ACTIVE', 'INACTIVE'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data.locations
}

const handleDelete =
  ({ session, app, id, action }) =>
  async () => {
    const variables = { id }
    const query = `
    mutation($id: ID!) {
      deleteTechnician(id: $id)
    }
  `
    const [ok] = await request({ query, variables }, { session, app })
    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  }
