import React from 'react'

export default ({ color }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 33 32"
  >
    <path
      fill={color || '#000'}
      d="M16.429 14.014c1.876 0 3.839 0.197 5.738 0.386 1.639 0.163 3.19 0.318 4.584 0.337v-8.588c0-3.851-3.859-6.15-10.32-6.15s-10.32 2.299-10.32 6.15v8.588c1.394-0.020 2.944-0.174 4.584-0.337 1.897-0.191 3.86-0.386 5.735-0.386zM5.497 12.108c-0.292 0.040-0.58 0.081-0.856 0.125-3.546 0.557-4.573 1.221-4.64 1.525 0.163 0.408 1.929 0.953 5.497 0.983zM26.967 29.365c-2.042 0-6.086-4.697-7.924-4.697s-2.615 1.389-2.615 1.389c0 0-0.775-1.389-2.613-1.389s-5.881 4.697-7.924 4.697c-2.042 0-2.369-0.98-2.369-0.98s0.675 3.615 3.512 3.615c2.41 0 5.805-1.943 8.214-2.529 0.696-0.169 1.18-0.803 1.18-1.52 0 0.717 0.484 1.351 1.18 1.52 2.407 0.588 5.803 2.529 8.214 2.529 2.839 0 3.512-3.615 3.512-3.615s-0.325 0.98-2.367 0.98zM27.057 15.354c-1.5 0-3.177-0.168-4.95-0.345-1.884-0.188-3.831-0.383-5.677-0.383s-3.793 0.195-5.677 0.383c-1.775 0.177-3.451 0.345-4.95 0.345-1.598 0-3.077-0.108-4.164-0.305-0.398-0.072-0.74-0.154-1.030-0.247 1.232 1.297 4.9 3.279 15.821 3.279s14.59-1.981 15.821-3.279c-0.29 0.093-0.632 0.174-1.030 0.247-1.087 0.197-2.566 0.305-4.164 0.305zM28.219 12.233c-0.276-0.043-0.563-0.084-0.856-0.125v2.632c3.567-0.029 5.333-0.575 5.497-0.983-0.067-0.302-1.094-0.966-4.64-1.523z"
    />
  </svg>
)
