import React from 'react'
import { useIntl } from 'react-intl'
import { getMessage } from 'utilities/app'
import Text from './Text'

export default ({ id, values, texts, ...props }) => {
  if (!id) return null

  const { formatMessage } = useIntl()
  const message = getMessage(formatMessage, { id, values, texts })

  if (!props || Object.keys(props).length === 0) {
    return message
  }

  return <Text {...props}>{message}</Text>
}
