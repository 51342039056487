import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import Login from 'views/Login'
import Dashboard from 'views/Dashboard'
import TechnicianList from 'views/TechnicianList'
import PartList from 'views/PartList'
import BuyList from 'views/BuyList'
import BuyPage from 'views/BuyPage'
import AdjustList from 'views/AdjustList'
import AdjustPage from 'views/AdjustPage'
import MoveList from 'views/MoveList'
import MovePage from 'views/MovePage'
import CheckinList from 'views/CheckinList'
import CheckinPage from 'views/CheckinPage'
import CheckoutList from 'views/CheckoutList'
import CheckoutPage from 'views/CheckoutPage'
import SellList from 'views/SellList'
import SellPage from 'views/SellPage'
import InventoryAllList from 'views/InventoryAllList'
import InventoryList from 'views/InventoryList'
import InventoryTechList from 'views/InventoryTechList'
import WarehouseList from 'views/WarehouseList'
import ReportList from 'views/ReportList'
import ReportRepairList from 'views/ReportRepairList'

const component = (Component, profile) => (props) => (
  <Component {...props} profile={profile} />
)

export const RootRoute = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/" component={MainLayout} />
  </Switch>
)

export const AppRoute = () => (
  <Switch>
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/technician" component={TechnicianList} />
    <Route exact path="/part" component={PartList} />
    <Route exact path="/buy" component={BuyList} />
    <Route path="/buy/add" render={component(BuyPage, 'add')} />
    <Route path="/buy/:id/edit" render={component(BuyPage, 'edit')} />
    <Route path="/buy/:id/view" render={component(BuyPage, 'view')} />
    <Route exact path="/adjust" component={AdjustList} />
    <Route path="/adjust/add" render={component(AdjustPage, 'add')} />
    <Route path="/adjust/:id/edit" render={component(AdjustPage, 'edit')} />
    <Route path="/adjust/:id/view" render={component(AdjustPage, 'view')} />
    <Route exact path="/move" component={MoveList} />
    <Route path="/move/add" render={component(MovePage, 'add')} />
    <Route path="/move/:id/edit" render={component(MovePage, 'edit')} />
    <Route path="/move/:id/view" render={component(MovePage, 'view')} />
    <Route exact path="/checkin" component={CheckinList} />
    <Route path="/checkin/add" render={component(CheckinPage, 'add')} />
    <Route path="/checkin/:id/edit" render={component(CheckinPage, 'edit')} />
    <Route path="/checkin/:id/view" render={component(CheckinPage, 'view')} />
    <Route exact path="/checkout" component={CheckoutList} />
    <Route path="/checkout/add" render={component(CheckoutPage, 'add')} />
    <Route path="/checkout/:id/edit" render={component(CheckoutPage, 'edit')} />
    <Route path="/checkout/:id/view" render={component(CheckoutPage, 'view')} />
    <Route exact path="/sell" component={SellList} />
    <Route path="/sell/add" render={component(SellPage, 'add')} />
    <Route path="/sell/:id/edit" render={component(SellPage, 'edit')} />
    <Route path="/sell/:id/view" render={component(SellPage, 'view')} />
    <Route exact path="/inventory/all" component={InventoryAllList} />
    <Route
      exact
      path="/inventory/warehouse"
      component={InventoryList}
    />
    <Route exact path="/inventory/technician" component={InventoryTechList} />
    <Route exact path="/warehouse" component={WarehouseList} />
    <Route exact path="/report" component={ReportList} />
    <Route exact path="/report/repair" component={ReportRepairList} />
    <Redirect from="/" to="/dashboard" />
  </Switch>
)
