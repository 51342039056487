import React from 'react'
import { Box } from 'reflexbox'

export default ({ checked, onClick, ...props }) => (
  <Box
    as="label"
    display="inline-block"
    width="60px"
    height={'34px'}
    sx={{ position: 'relative' }}
    {...props}
  >
    <Box
      as="input"
      type="checkbox"
      opacity={0}
      width={1}
      height="100%"
      onClick={onClick}
    />
    <Box
      as="span"
      bg={checked ? 'accent.1' : 'dark.0'}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '34px',
        cursor: 'pointer',
        ':focus': {
          boxShadow: '0 0 1px #333',
        },
        ':before': {
          position: 'absolute',
          content: '""',
          height: '26px',
          width: '26px',
          left: '4px',
          bottom: '4px',
          borderRadius: '50%',
          backgroundColor: '#fff',
          transition: '.2s',
          transform: checked ? 'translateX(26px)' : 'none',
        },
      }}
    />
  </Box>
)
