import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/partDrawer'
import { Drawer, Center, Button } from 'components/core'

export default ({ value, open, action }) => {
  const profile = value.id ? 'edit' : 'add'
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value))

  const form = fields({ app, session, state, setState, message })
  const handler = handlers({ app, session, state, setState, action })

  useEffect(() => {
    setState(initialState(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      onClose={action.handleClose}
      title={`part.title.${profile}`}
      open={open}
      as="form"
      onSubmit={handler.handleSubmit}
    >
      {form.name}
      {form.sku}
      {form.barcode}
      {form.location}
      {form.device}
      {form.price}
      {form.image}
      {form.image2}
      {form.image3}
      {form.isActive}
      <Center mt={3}>
        <Button
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button variant="primary" text="btn.submit" type="submit" />
      </Center>
    </Drawer>
  )
}
