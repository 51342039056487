import React from 'react'
import { request } from 'utilities/request'
import { Button, StatusChip } from 'components/core'
import { textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { renderDeleteButton } from 'utilities/app'
import { MdEdit, MdPhoto } from 'react-icons/md'
import { getStatusColor } from 'constants/status'
import { FILTER_SET } from 'constants/actionType'

export const filters = [
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter,
  },
  {
    id: 'barcode',
    label: 'part.field.barcode',
    input: textFilter,
  },
  {
    id: 'location',
    label: 'part.field.location',
    input: textFilter,
  },
  {
    id: 'device',
    label: 'part.field.device',
    input: textFilter,
  },
]

export const columns = ({ app, session, message, action }) => [
  {
    id: 'name',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'barcode',
    label: 'part.field.barcode',
  },
  {
    id: 'location',
    label: 'part.field.location',
    render: ({ row }) => row.extra?.location,
  },
  {
    id: 'device',
    label: 'part.field.device',
    render: ({ row }) => {
      const device = row.extra?.device
      if (device && device.indexOf('.')) {
        return device.split('.').join(', ')
      }
      return device
    },
  },
  {
    id: 'price',
    label: 'part.field.price',
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    noWrap: true,
    renderHtml: ({ row }) => (
      <StatusChip
        label={`part.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => {
      const { image, image2, image3 } = row.extra || {}
      const hasImage = image || image2 || image3
      return (
        <>
          {hasImage && (
            <Button
              mr={2}
              variant="icon"
              icon={<MdPhoto />}
              onClick={() =>
                action.handleImageOpen({
                  partId: row.id,
                  images: [image, image2, image3],
                })
              }
            />
          )}
          <Button
            mr={2}
            variant="icon"
            icon={<MdEdit />}
            onClick={() => action.handleOpen(row)}
          />
          {renderDeleteButton({
            module: 'part',
            session,
            status: row.status,
            onSubmit: () => handleDelete({ session, app, id: row.id, action }),
          })}
        </>
      )
    },
  },
]

export const handlers = ({
  session,
  app,
  setState,
  setFilterValues,
  setPagination,
  message,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'partList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'part.title.list'
    const cols = columns({ message })
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: ProductQueryInput) {
      partCount(input: $input)
      parts(input: $input) {
        id
        name
        sku
        barcode
        price
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.parts,
    pagination: getPagination(page, countPerPage, data.partCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: ProductQueryInput) {
      parts(input: $input) {
        id
        name
        sku
        barcode
        price
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.parts
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const barcode = filterValues.find(({ id }) => id === 'barcode')
  const location = filterValues.find(({ id }) => id === 'location')
  const device = filterValues.find(({ id }) => id === 'device')
  const input = {
    productVariantName: name?.value,
    sku: sku?.value,
    barcode: barcode?.value,
    extra: {
      location: location?.value,
      device: device?.value,
    },
    orderBy: [
      { key: 'status', fields: ['ACTIVE', 'INACTIVE'] },
      { key: 'name' },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function handleDelete({ session, app, id, action }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deletePart(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok && action) action.handleLoad()
  return ok
}
