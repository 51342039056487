export const snakeToCamel = (str) => {
  return str.replace(/(_\w)/g, (m) => m[1].toUpperCase())
}

export const camelToSnake = (str) => {
  return str.replace(/[\w]([A-Z])/g, (m) => m[0] + '_' + m[1]).toLowerCase()
}

export const getCanonicalValue = (str) => {
  if (typeof str !== 'string') return str
  return str.replace(/[\s-().,]/g, '')
}

export const getNumericValue = (str) => {
  if (typeof str !== 'string') return ''
  return str.replace(/\D/g, '')
}

export const capitalize = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
