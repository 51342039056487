import React, { useState, useContext, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/warehouseList'
import { Button, Section, Table } from 'components/core'
import Page from 'components/Page'
import WarehouseModal from 'views/WarehouseModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState()
  const [value, setValue] = useState()
  const { handleLoad } = handlers({ setState, session, app })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const action = { handleOpen, handleClose, handleLoad }

  return (
    <Page
      title="warehouse.title.list"
      navId="warehouseList"
      action={
        <Button
          variant="secondary"
          text="btn.add"
          onClick={() => handleOpen({})}
        />
      }
    >
      <Section>
        <Table
          columns={columns({ setState, session, app, action })}
          rows={state}
        />
      </Section>
      <WarehouseModal open={open} value={value} action={action} />
    </Page>
  )
}
