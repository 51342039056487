export function getStatusColor(status) {
  switch (status) {
    case 'ACTIVE':
      return 'success.1'
    case 'PENDING':
    case 'TIME_PENDING':
    case 'CALLBACK_PENDING':
      return 'warning.1'
    default:
      return 'dark.0'
  }
}
