export default {
  'checkin.title.list': '零件歸還目錄',
  'checkin.title.add': '新增歸還單',
  'checkin.title.edit': '修改歸還單',
  'checkin.title.view': '歸還單詳情',
  'checkin.section.basic': '基礎信息',
  'checkin.section.part': '歸還零件',
  'checkin.field.fromLocation': '取倉',
  'checkin.field.balance': '攜出數量',
}
