import { useState } from 'react'

// export function useCallbackOnce(callback) {
//   const [called, setCalled] = useState(false)

//   return async (event) => {
//     event.preventDefault()
//     if (called) {
//       setCalled(false)
//       return
//     }

//     try {
//       await callback(event)
//     } finally {
//       setCalled(true)
//     }
//   }
// }

export function useCallbackOnce(callback, delay = 600) {
  const [lastClicked, setLastClicked] = useState(0)

  return async (event) => {
    event.preventDefault()

    const curTime = new Date().getTime()
    if (curTime - lastClicked < delay) return

    try {
      await callback(event)
    } finally {
      setLastClicked(curTime)
    }
  }
}

// export function useCallbackOnce(callback) {
//   const api = useCancellablePromises()
//   const delay = (n) => new Promise((resolve) => setTimeout(resolve, n))

//   return () => {
//     api.clearPendingPromises()
//     const waitForClick = cancellablePromise(delay(300))
//     api.appendPendingPromise(waitForClick)

//     return waitForClick.promise
//       .then(() => {
//         api.removePendingPromise(waitForClick)
//         callback()
//       })
//       .catch((errorInfo) => {
//         api.removePendingPromise(waitForClick)
//         if (!errorInfo.isCanceled) {
//           throw errorInfo.error
//         }
//       })
//   }
// }
