import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  showDate,
  getSelectOptions,
  getSelectOption,
  handleTextChange,
  getDate,
  showAddress,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Definition, Link, Table } from 'components/core'
import {
  Select,
  NumberInput,
  TextInput,
  DateInput,
  TextArea,
} from 'components/form'
import { printHtml } from 'utilities/print'
import {
  getInitialItemInput,
  handleDelete,
  handleItemAdd,
  handleItemDelete,
  setBalances,
} from 'actions/ticket'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { getPartOptions, getSkuOptions } from 'actions/part'

export const initialState = (value = {}) => {
  const technicians = getSelectOptions(value.locations)
  return {
    id: value.id,
    status: value.status || 'PENDING',
    hash: value.hash,
    techName: value.fromLocationName || '',
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    inputValues: getInitialItemInput({ initPrice: true }, { memo: '' }),
    editValues: {},
    parts: value.parts || [],
    partOptions: getPartOptions(value.parts),
    skuOptions: getSkuOptions(value.parts),
    technicians,
    receipts: value.receipts || [],
    ...initializeState({
      transDate: getDate(value.transDate),
      techId: getSelectOption(technicians, value.fromLocationId),
      repairId: value.repairId || value.extra?.repairId || '',
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  techId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'id', label: 'field.ticketId' },
  {
    id: 'transDate',
    label: 'sell.field.transDate',
    render: (state) => showDate(state.transDate),
  },
  {
    id: 'repairId',
    label: 'sell.field.repairId',
    renderHtml: (state) => (
      <Link
        mt={1}
        variant="primaryLink"
        href={`${process.env.REACT_APP_STOCK_URL}/repair/${state.repairId}/view`}
        target="_blank"
      >
        {state.repairId}
      </Link>
    ),
  },
  {
    id: 'techId',
    label: 'field.technician',
    render: (state) => state.techName,
  },
  {
    id: 'memo',
    label: 'field.memo',
    render: (state) => state.memo,
  },
]

const columns = [
  { id: 'productVariantName', label: 'field.partName' },
  { id: 'sku', label: 'field.sku' },
  {
    id: 'location',
    label: 'part.field.location',
    render: ({ row }) => row.extra?.location,
  },
  { id: 'quantity', label: 'field.quantity', align: 'right' },
  {
    id: 'unitPrice',
    label: 'field.unitPrice',
    align: 'right',
    render: ({ row }) => row.extra?.unitPrice,
  },
  { id: 'price', label: 'field.subTotal', align: 'right' },
  {
    id: 'memo',
    label: 'field.memo',
    render: ({ row }) => row.extra?.memo,
  },
]

const receiptColumns = ({ message }) => [
  {
    id: 'transDate',
    label: 'field.date',
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'receiptNo',
    label: 'sell.field.receiptNo',
    render: ({ row }) => row.receiptNo,
  },
  {
    id: 'receiptAddress',
    label: 'field.address',
    render: ({ row }) => showAddress(row.receiptAddress, message),
  },
  {
    id: 'price',
    label: 'field.price',
  },
  {
    id: 'memo',
    label: 'field.memo',
    render: ({ row }) => row.memo,
  },
]

export const labels = ({ state, message }) => {
  const content = defs.reduce((result, { id, label, renderHtml, render }) => {
    let value = state[id]
    if (render) value = render(state)
    if (renderHtml) value = renderHtml(state)

    switch (id) {
      case 'repairId':
        result[id] = <Definition label={label}>{value}</Definition>
        break
      default:
        result[id] = <Definition label={label} value={value} />
    }
    return result
  }, {})

  content.ticketItems = (
    <Table showSeq columns={columns} rows={state.ticketItems} />
  )
  content.receipts = (
    <Table columns={receiptColumns({ message })} rows={state.receipts} />
  )
  return content
}

export const fields = ({ app, session, state, setState, profile }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="sell.field.transDate"
        min="1970-01-01"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    techId: (
      <Select
        isClearable={false}
        id="techId"
        label="field.technician"
        placeholder="field.technician"
        options={state.technicians}
        value={state.techId}
        onChange={onSelectChange('techId', async ({ value }) => {
          const { ticketItems, oldTicketItems } = state
          const allBalances = await fetchAllBalances(app, session, value)

          const partItems = allBalances.map((i) => i.productVariantId)
          const parts = state.parts.filter((i) => partItems.includes(i.id))
          const partOptions = getPartOptions(parts)
          const skuOptions = getSkuOptions(parts)

          const itemId = ticketItems.map((i) => i.productVariantId)
          const balances = allBalances.filter((i) =>
            itemId.includes(i.productVariantId),
          )
          if (oldTicketItems) {
            balances.forEach((balance) => {
              const matched = oldTicketItems.find(
                (item) => item.productVariantId === balance.productVariantId,
              )
              if (matched) balance.quantity += matched.quantity
            })
          }
          setBalances(ticketItems, balances)
          const inputValues = getInitialItemInput(
            { initPrice: true },
            { memo: '' },
          )
          return { partOptions, skuOptions, ticketItems, inputValues }
        })}
        errMsg={state.__error__.techId}
      />
    ),
    repairId: (
      <TextInput
        id="repairId"
        label="sell.field.repairId"
        placeholder="sell.field.repairId"
        value={state.repairId}
        onChange={onTextChange('repairId')}
        errMsg={state.__error__.repairId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    ticketItems: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.partOptions}
                onChange={async ({ value }) => {
                  const part = state.parts.find(({ id }) => id === value)
                  const balance = await fetchBalance(
                    app,
                    session,
                    'TECHNICIAN',
                    state.techId?.value,
                    value,
                    state.oldTicketItems,
                  )
                  let { unitPrice, quantity } = state.inputValues
                  if (!unitPrice) unitPrice = part.price || 0
                  const price = unitPrice * quantity
                  return {
                    sku: { value: part.id, label: part.sku },
                    balance,
                    unitPrice,
                    price,
                  }
                }}
              />
            ),
            getValue: (row) => {
              return getSelectOption(state.partOptions, row.productVariantId)
            },
          },
          {
            id: 'sku',
            label: 'field.sku',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.skuOptions}
                onChange={async ({ value }) => {
                  const part = state.parts.find(({ id }) => id === value)
                  const balance = await fetchBalance(
                    app,
                    session,
                    'TECHNICIAN',
                    state.techId?.value,
                    value,
                    state.oldTicketItems,
                  )
                  const unitPrice = part.price || 0
                  const price = unitPrice * state.inputValues.quantity
                  const result = { value: part.id, label: part.name }
                  return {
                    productVariantName: result,
                    balance,
                    unitPrice,
                    price,
                  }
                }}
              />
            ),
            getValue: (row) => {
              return getSelectOption(state.skuOptions, row.productVariantId)
            },
          },
          {
            id: 'balance',
            label: 'checkin.field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '96px',
            align: 'right',
            renderInput: () => (
              <NumberInput
                min="1"
                max={state.inputValues.balance}
                onChange={(value) => {
                  const { productVariantName } = state.inputValues
                  if (!productVariantName) return {}

                  const unitPrice = state.inputValues.unitPrice || 0
                  const price = unitPrice * value
                  return { price }
                }}
              />
            ),
          },
          {
            id: 'unitPrice',
            label: 'field.unitPrice',
            width: '96px',
            align: 'right',
            getValue: (row) => row.extra?.unitPrice,
            renderInput: () => {
              return (
                <NumberInput
                  type="decimal"
                  min="0"
                  onChange={(value) => {
                    const { productVariantName } = state.inputValues
                    if (!productVariantName) return {}

                    const quantity = state.inputValues.quantity || 0
                    const price = quantity * value
                    return { price }
                  }}
                />
              )
            },
            render: ({ row }) => row.extra?.unitPrice,
          },
          {
            id: 'price',
            label: 'field.subTotal',
            width: '96px',
            align: 'right',
            renderInput: () => <NumberInput type="decimal" min="0" />,
          },
          {
            id: 'memo',
            label: 'field.memo',
            width: '96px',
            getValue: (row) => row.extra?.memo,
            renderInput: () => <TextInput />,
            render: ({ row }) => row.extra?.memo,
          },
        ]}
        rows={state.ticketItems}
        showAddInput
        showDeleteIcon
        inputValues={state.inputValues}
        editValues={state.editValues}
        onInputChange={(value) => setState({ ...state, inputValues: value })}
        onEditChange={(value) => setState({ ...state, editValues: value })}
        onAdd={({ row }) =>
          handleItemAdd({ session, state, setState, row, extraKeys: ['memo'] })
        }
        onEdit={({ row, index }) => {
          const { sku, productVariantName, balance, memo } = row
          if (!productVariantName.value) return false

          const productVariantId = productVariantName.value
          const part = state.parts.find(({ id }) => id === productVariantId)
          const price = parseFloat(row.price)
          const unitPrice = parseFloat(row.unitPrice)
          const quantity = parseInt(row.quantity)
          const ticketItems = cloneDeep(state.ticketItems)
          const ticketItem = {
            ...ticketItems[index],
            productVariantId,
            productVariantName: productVariantName.label,
            sku: sku.label,
            balance: parseInt(balance),
            quantity: parseInt(quantity),
            price: parseFloat(price >= 0 ? price : unitPrice * quantity),
            extra: { location: part.extra?.location, unitPrice, memo },
          }
          ticketItems.splice(index, 1, ticketItem)
          setState({ ...state, ticketItems })
          return true
        }}
        onDelete={({ index }) => handleItemDelete({ state, setState, index })}
      />
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  profile,
  id,
  repairId,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, profile, id })
    data.repairId = repairId
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editSell(state, app, session)
      : await addSell(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartSellTicket

    if (repairId) {
      window.location.href = `${process.env.REACT_APP_STOCK_URL}/repair/${repairId}/view`
    } else {
      history.push(`/sell/${id}/view`)
    }
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partSell', { session, app, id, hash })
    if (!ok) return false

    history.push('/sell')
    return true
  },
  handlePrint: () => {
    const title = 'sell.title.view'
    const field = defs.map(({ id, label, render }) => {
      const value = render ? render(state) : state[id]
      return { label, value }
    })
    const list = { columns, rows: state.ticketItems }
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: list },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, profile, id }) {
  const locationInput = { type: ['TECHNICIAN'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      parts {
        id
        name
        sku
        price
        extra
      }
      partSellTicket(id: $id) {
        fromLocationId
        fromLocationName
        transDate
        status
        hash
        extra
      }
      partSellTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        extra
      }
      repairTicket(id: $id) {
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partSellTicket || {}
  const ticketItems = data.partSellTicketItems?.map((item) => ({
    ...item,
    location: item.extra?.location,
  }))
  const oldTicketItems = cloneDeep(ticketItems)

  if (profile === 'edit' && ticketItems.length > 0) {
    const balances = await fetchBalances(
      app,
      session,
      'TECHNICIAN',
      ticket.fromLocationId,
      ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
    )
    setBalances(ticketItems, balances)
  }

  const repairId = ticket.extra?.repairId
  const receipts = await getReceiptData({ app, session, profile, repairId })

  return {
    id,
    hash: ticket.hash,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    transDate: ticket.transDate,
    extra: ticket.extra,
    status: ticket.status,
    ticketItems,
    oldTicketItems,
    parts: data.parts,
    locations: data.locations,
    receipts,
  }
}

async function getReceiptData({ app, session, profile, repairId }) {
  if (profile !== 'view' || !repairId) return []

  const variables = { id: repairId }
  const query = `
    query($id: ID) {
      repairTicket(id: $id) {
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  return data.repairTicket?.extra.receipts
}

async function addSell(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartSellTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editSell(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartSellTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, memo, techId, repairId, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (item.price !== newItem.price) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    parentId: repairId || null,
    transDate: state.transDate,
    fromLocationId: techId.value,
    extra: { repairId, memo },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

export function getTotalPrice(state) {
  const { ticketItems = [] } = state
  const total = ticketItems.reduce((result, item) => {
    if (item.status === 'INACTIVE') return result
    result += parseFloat(item.price || 0)
    return result
  }, 0)
  return Math.round(total * 100) / 100
}

async function fetchAllBalances(app, session, locationId) {
  const input = { locationType: 'TECHNICIAN', locationId }
  if (!locationId) return []

  const variables = { input }
  const query = `
    query($input: InventoryInput!) {
      inventoryPartBalances(input: $input) {
        productVariantId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.inventoryPartBalances.filter((item) => item.quantity > 0)
}
