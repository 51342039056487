export default {
  'report.title': '報表匯出',
  'report.title.list': '報表匯出',
  'report.title.stockList': '零件進出統計表',
  'report.title.repairList': '零件維修紀錄',
  'report.title.partReports': '報表匯出',
  'report.title.history': '零件歷史紀錄',
  'report.field.buyQuantity': '進貨量',
  'report.field.checkoutQuantity': '攜出量',
  'report.field.checkinQuantity': '歸還量',
  'report.field.sellQuantity': '銷貨量',
  'report.field.adjustQuantity': '調整量',
  'report.field.repairDate': '維修日期',
  'report.field.ticketDate': '訂單日期',
  'report.field.repairNo': '維修單號',
  'report.field.repairType': '維修類型',
  'report.field.repairTag': '註記',
  'report.field.tech': '維修人員',
  'report.field.receiptNo': '發票號碼',
  'report.field.phone': '電話',
  'report.field.cellphone': '手機',
  'report.field.address': '地址',
  'report.field.recipientContact': '客戶姓名',
  'report.field.symptom': '報修內容',
  'report.field.solution': '維修內容',
  'report.field.productVariantName': '商品名稱',
  'report.field.sku': '商品品號',
  'report.field.model': '產品機號',
  'report.field.productRepairDate': '安裝/送貨日期',
  'report.field.serialNo': 'ART',
  'report.field.warrantyDate': '保固日期',
  'report.field.productMemo': '商品備註',
  'report.field.sellMemo': '銷貨備註',
}
