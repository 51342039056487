export const isActive = (items, id) => {
  if (!items || !id) {
    return false
  }

  for (const item of items) {
    if (item.id === id || isActive(item.items, id)) {
      return true
    }
  }

  return false
}

export const cartesian = (a, b, ...c) =>
  b ? cartesian(cartesianJoin(a, b), ...c) : a

function cartesianJoin(a, b) {
  return [].concat(...a.map((a) => b.map((b) => [].concat(a, b))))
}

export function listToTree(list, { idKey = 'id', parentIdKey = 'parentId' }) {
  const map = {}
  const roots = []

  for (let i = 0; i < list.length; i++) {
    map[list[i][idKey]] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i]
    const parentId = node[parentIdKey] || '0'

    if (parentId === '0') {
      roots.push(node)
    } else {
      list[map[parentId]].children.push(node)
    }
  }

  return roots
}

export function getDiff(items, newItems, isKeyEqual, isValEqual) {
  const added = []
  const removed = []
  const modified = []
  const unchanged = []
  const matched = []

  for (let newItem of newItems) {
    let isMatch = false

    for (let item of items) {
      if (isKeyEqual(item, newItem)) {
        isMatch = true
        matched.push(newItem)
        if (isValEqual(item, newItem)) {
          unchanged.push(newItem)
        } else {
          modified.push({ before: item, after: newItem })
        }
        break
      }
    }

    if (!isMatch) {
      added.push(newItem)
    }
  }

  for (let item of items) {
    let isMatch = false

    for (let matchedItem of matched) {
      if (isKeyEqual(item, matchedItem)) {
        isMatch = true
        break
      }
    }

    if (!isMatch) {
      removed.push(item)
    }
  }

  return { added, removed, modified, unchanged }
}
