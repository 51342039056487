export const getPart = (parts, partId) => {
  if (!parts || !partId) return {}
  return parts.find(({ id }) => id === partId)
}

export const getPartOption = (parts, partId) => {
  const part = getPart(parts, partId)
  if (Object.keys(part).length === 0) return null
  return { value: part.id, label: part.name }
}

export const getPartOptions = (parts) => {
  if (!parts) return []
  return parts.map((item) => ({
    value: item.id,
    label: item.name,
  }))
}

export const getSkuOptions = (parts) => {
  if (!parts) return []
  return parts.map((item) => ({
    value: item.id,
    label: item.sku,
  }))
}
