import React from 'react'
import { Flex } from 'reflexbox'

export default ({ show = true, children, ...props }) => {
  if (!show) return null
  return (
    <Flex
      py={2}
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'light.3',
      }}
      {...props}
    >
      {children}
    </Flex>
  )
}
