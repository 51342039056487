import React from 'react'
import { Box } from 'reflexbox'
import { Message, Link } from 'components/core'

export default ({
  label,
  icon,
  active = false,
  onClick = () => {},
  variant,
  ...props
}) => (
  <Box as="li">
    <Link
      display="flex"
      variant={variant}
      icon={icon}
      iconProps={{ justifyContent: 'center', mr: 3, width: '30px' }}
      onClick={onClick}
      {...props}
    >
      <Message
        flex="1"
        fontSize={1}
        id={label}
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      />
    </Link>
  </Box>
)
