import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { Flex, Box } from 'reflexbox'
import {
  initialState,
  fields,
  labels,
  handlers,
  getTotalPrice,
} from 'models/sellPage'
import {
  Center,
  Grid,
  Text,
  Message,
  Button,
  Link,
  Section,
  StatusChip,
} from 'components/core'
import Page from 'components/Page'
import { MdArrowBack, MdEdit, MdPrint } from 'react-icons/md'
import { renderDeleteLink } from 'utilities/app'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const repairId = url.searchParams.get('repairId')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({ repairId }))

  const readonly = profile === 'view'
  const form = readonly
    ? labels({ state, setState, message })
    : fields({ app, session, state, setState, profile })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    history,
    profile,
    id,
    repairId,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  const renderTableFooter = (label, value, showBorder = true) => (
    <Flex
      pt={showBorder ? 3 : 2}
      justifyContent="flex-end"
      sx={{
        borderTopWidth: showBorder ? '1px' : 0,
        borderTopStyle: 'solid',
        borderTopColor: 'light.3',
      }}
    >
      <Message id={label} color="dark.1" />
      <Text mx={2} sx={{ textAlign: 'right' }}>
        {value}
      </Text>
    </Flex>
  )

  return (
    <>
      <Page
        navId="sellList"
        title={`sell.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/sell"
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'sell',
              session,
              readonly,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              href={`/sell/${id}/edit`}
            />
            <Button
              ml={3}
              show={!readonly}
              variant="primary"
              text="btn.save"
              onClick={handler.handleSubmit}
            />
          </Center>
        }
        showFooterAction
        as="form"
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.transDate}
          </Section>
          <Section>
            {form.techId}
            {form.repairId}
          </Section>
          <Section>{form.memo}</Section>
        </Grid>
        <Box mt={4}>
          <Section title="sell.section.part">
            {form.ticketItems}
            {renderTableFooter('field.total', getTotalPrice(state))}
          </Section>
        </Box>
        {profile === 'view' && (
          <Box mt={4}>
            <Section title="sell.section.receipt">{form.receipts}</Section>
          </Box>
        )}
      </Page>
    </>
  )
}
