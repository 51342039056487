import React, { useContext, useEffect, useState } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { setAddressOptions } from 'actions/address'
import { Flex } from 'reflexbox'
import TextInput from './TextInput'
import FormField from './FormField'
import { Checkbox, Select } from '.'
import { parseAddress } from 'utilities/form'

export default ({ id, label, value, errMsg, fieldProps, onChange }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { cityMap, cityOptions, districtMap, districtOptions } = app.state
  const [zipcode, setZipcode] = useState(value?.zipcode || '')
  const [city, setCity] = useState(value?.city || '')
  const [district, setDistrict] = useState(value?.district || '')
  const [street, setStreet] = useState(value?.street || '')
  const [hasLift, setHasLift] = useState(value?.hasLift || false)

  useEffect(() => {
    setAddressOptions({ app, session, cityMap, districtMap })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      const address = handleLoad(cityMap, districtMap, value)
      setZipcode(address.zipcode || '')
      setCity(address.city || '')
      setDistrict(address.district || '')
      setStreet(address.street || '')
      setHasLift(address.hasLift || false)
      onChange(address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (item) => {
    const result = { zipcode, city, district, street, hasLift }
    if (item.zipcode !== undefined) result.zipcode = item.zipcode
    if (item.city !== undefined) result.city = item.city
    if (item.district !== undefined) result.district = item.district
    if (item.street !== undefined) result.street = item.street
    if (item.hasLift !== undefined) result.hasLift = item.hasLift
    onChange(result)
  }

  return (
    <FormField id={id} label={label} errMsg={errMsg} {...fieldProps}>
      <Flex>
        <Select
          id={`${id}_city`}
          fieldProps={{ flex: 1, m: 0, mr: 2, width: 1 }}
          isClearable={false}
          placeholder="field.city"
          options={cityOptions}
          value={getOption(city)}
          onChange={({ value }) => {
            handleChange({
              city: value,
              district: '',
              zipcode: '',
            })
          }}
        />
        <Select
          id={`${id}_district`}
          fieldProps={{ flex: 1, m: 0, width: 1 }}
          isClearable={false}
          placeholder="field.district"
          options={getDistricts(districtOptions, city)}
          value={getOption(district)}
          onChange={({ value }) => {
            handleChange({
              district: value,
              zipcode: districtMap[value]?.zipcode,
            })
          }}
        />
      </Flex>
      <TextInput
        id={`${id}_street`}
        fieldProps={{ mx: 0, my: 2, width: 1 }}
        placeholder="field.street"
        value={street}
        onChange={(val) => setStreet(val)}
        onBlur={({ target }) => {
          handleChange({ street: target.value?.trim() })
        }}
      />
      <Flex justifyContent="space-between">
        <TextInput
          id={`${id}_zipcode`}
          fieldProps={{ m: 0, width: '78px' }}
          maxLength={5}
          placeholder="field.zipcode"
          value={zipcode}
          onChange={(val) => {
            handleChange({ zipcode: val })
          }}
        />
        <Checkbox
          label="field.elevator"
          checked={hasLift}
          onClick={() => {
            const val = !hasLift
            handleChange({ hasLift: val })
          }}
          sx={{ color: 'dark.0' }}
        />
      </Flex>
    </FormField>
  )
}

function handleLoad(cityMap, districtMap, value) {
  if (!value) return {}

  let address = value
  if (typeof address === 'string') {
    const cities = Object.keys(cityMap)
    const districts = Object.keys(districtMap)
    address = parseAddress(cityMap, cities, districtMap, districts, value)
  }

  return address
}

function getOption(value) {
  if (!value) return {}
  return { value, label: value }
}

function getDistricts(districtMap, cityName) {
  if (!cityName || !districtMap) return []
  return districtMap[cityName]
}
