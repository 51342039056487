import React from 'react'
import { Flex } from 'reflexbox'
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox,
} from 'react-icons/md'
import { renderMessage } from 'utilities/app'

export default ({
  label,
  checked,
  isPartial = false,
  onChange,
  sx,
  ...props
}) => {
  return (
    <Flex
      alignItems="center"
      onClick={onChange}
      sx={{
        cursor: 'pointer',
        userSelect: 'none',
        ...sx,
      }}
      {...props}
    >
      <Flex
        mr={1}
        alignItems="center"
        color={checked ? 'accent.1' : 'dark.0'}
        fontSize={4}
        sx={{
          '&:hover': {
            color: 'accent.1',
          },
        }}
      >
        {checked ? (
          isPartial ? (
            <MdIndeterminateCheckBox />
          ) : (
            <MdCheckBox />
          )
        ) : (
          <MdCheckBoxOutlineBlank />
        )}
      </Flex>
      {renderMessage(label)}
    </Flex>
  )
}
