import React from 'react'
import { request } from 'utilities/request'
import { getLimit, getPagination } from 'utilities/pagination'
import { selectFilter, textFilter } from 'utilities/filter'
import { Link } from 'components/core'
import { printHtml } from 'utilities/print'

export const filters = [
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter,
  },
  {
    id: 'stockStatus',
    label: 'part.field.stockStatus',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: [
          {
            value: 1,
            label: props.message({ id: 'part.stockStatus' }, { multiple: 1 }),
          },
          {
            value: 3,
            label: props.message({ id: 'part.stockStatus' }, { multiple: 3 }),
          },
          {
            value: 6,
            label: props.message({ id: 'part.stockStatus' }, { multiple: 6 }),
          },
          {
            value: 12,
            label: props.message({ id: 'part.stockStatus' }, { multiple: 12 }),
          },
        ],
      }),
  },
]

export const columns = ({ action }) => [
  {
    id: 'productVariantName',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'productLocation',
    label: 'part.field.location',
  },
  {
    id: 'price',
    label: 'part.field.price',
    align: 'right',
  },
  {
    id: 'averageQuantity',
    label: 'part.field.monthlyAvgQuantity',
    align: 'right',
    render: ({ row }) =>
      row.averageQuantity ? Math.round(row.averageQuantity * 100) / 100 : 0,
  },
  {
    id: 'quantity',
    label: 'inventory.field.balance',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleOpen({
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.quantity}
      </Link>
    ),
  },
  {
    id: 'checkoutQuantity',
    label: 'inventory.field.checkoutQuantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleCheckoutOpen({
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.checkoutQuantity}
      </Link>
    ),
  },
]

export const handlers = ({
  session,
  app,
  state,
  message,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handlePrint: async ({ filterValues }) => {
    const rows = await getExportData({ session, app, filterValues })
    const title = 'inventory.title.allList'
    const list = { columns: columns({ message }), rows }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'inventory.title.allList'
    const cols = columns({ message })
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, filterValues = [], pagination }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryPartAllCount(input: $input)
      inventoryPartAllList(input: $input) {
        productVariantId
        productVariantName
        sku
        productLocation
        quantity
        checkoutQuantity
        averageQuantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination || {}
  return {
    data: data.inventoryPartAllList,
    pagination: getPagination(page, countPerPage, data.inventoryPartAllCount),
  }
}

async function getExportData({ session, app, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryPartAllList(input: $input) {
        productVariantId
        productVariantName
        sku
        productLocation
        quantity
        checkoutQuantity
        averageQuantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.inventoryPartAllList
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const stockStatus = filterValues.find(({ id }) => id === 'stockStatus')
  const input = {
    productVariantName: name?.value,
    sku: sku?.value,
    avgMultiple: stockStatus?.value.value,
    orderBy: [{ key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
