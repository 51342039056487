import React from 'react'
import { request } from 'utilities/request'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'

export const filters = ({ data }) => [
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'partName',
    label: 'field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'field.sku',
    input: textFilter,
  },
]

export const columns = ({ filterValues, action }) => [
  {
    id: 'productVariantName',
    label: 'field.partName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'locationName',
    label: 'part.field.location',
    format: ['html', 'excel'],
  },
  {
    id: 'buyQuantity',
    label: 'report.field.buyQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: 'PART_BUY',
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.buyQuantity}
      </Link>
    ),
  },
  {
    id: 'checkoutQuantity',
    label: 'report.field.checkoutQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: 'PART_CHECKOUT',
            locationType: 'TECHNICIAN',
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.checkoutQuantity}
      </Link>
    ),
  },
  {
    id: 'checkinQuantity',
    label: 'report.field.checkinQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: 'PART_CHECKIN',
            locationType: 'TECHNICIAN',
            productVariantId: row.productVariantId,
          })
        }}
      >
        {Math.abs(row.checkinQuantity)}
      </Link>
    ),
    render: ({ row }) => Math.abs(row.checkinQuantity),
  },
  {
    id: 'sellQuantity',
    label: 'report.field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: 'PART_SELL',
            productVariantId: row.productVariantId,
          })
        }}
      >
        {Math.abs(row.sellQuantity)}
      </Link>
    ),
    render: ({ row }) => Math.abs(row.sellQuantity),
  },
  {
    id: 'adjustQuantity',
    label: 'report.field.adjustQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: 'PART_ADJUST',
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.adjustQuantity}
      </Link>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: PartReportInput) {
      partReportCount(input: $input)
      partReports(input: $input) {
        productVariantId
        productVariantName
        sku
        locationName
        buyQuantity
        checkoutQuantity
        checkinQuantity
        sellQuantity
        adjustQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.partReports,
    pagination: getPagination(page, countPerPage, data.partReportCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: PartReportInput) {
      partReports(input: $input) {
        productVariantName
        sku
        locationName
        buyQuantity
        checkoutQuantity
        checkinQuantity
        sellQuantity
        adjustQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.partReports
}

function getDataInput({ filterValues = [], pagination }) {
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const partName = filterValues.find(({ id }) => id === 'partName')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const input = {
    transDate: transDate?.value,
    productVariantName: partName?.value,
    sku: sku?.value,
    orderBy: [{ key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
