export default {
  'move.error.location.missing': '請先選取倉庫 / 維修人員',
  'move.title.list': '零件調撥目錄',
  'move.title.add': '新增調撥單',
  'move.title.edit': '修改調撥單',
  'move.title.view': '調撥單詳情',
  'move.btn.add': '新增調撥',
  'move.section.basic': '基礎信息',
  'move.section.part': '調撥零件',
  'move.field.fromTech': '從倉庫 / 維修人員',
  'move.field.toTech': '至倉庫 / 維修人員',
}
