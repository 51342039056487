import React from 'react'
import { Center, Button } from 'components/core'
import {
  MdMoreHoriz,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md'

export default ({ value, onClick }) => {
  const { pages, page, prevPage, nextPage, totalPage } = value

  const renderFirst = () => {
    if (pages[0] === 1) return null
    return (
      <Button
        key={`page-first`}
        variant="icon"
        onClick={() => onClick(1)}
        p={2}
        sx={{ color: 'dark.1' }}
      >
        {1}
      </Button>
    )
  }

  const renderFirstEllipsis = () => {
    if (pages[0] <= 2) return null
    return <MdMoreHoriz color="#999" />
  }

  const renderLast = () => {
    if (pages[pages.length - 1] === totalPage) return null
    return (
      <Button
        key={`page-last`}
        variant="icon"
        onClick={() => onClick(totalPage)}
        p={2}
        sx={{ color: 'dark.1' }}
      >
        {totalPage}
      </Button>
    )
  }

  const renderLastEllipsis = () => {
    if (pages[pages.length - 1] >= totalPage - 1) return null
    return <MdMoreHoriz color="#999" />
  }

  const renderPages = () =>
    pages.map((item) => {
      const isActive = page === item
      return (
        <Button
          key={`page-${item}`}
          variant="icon"
          onClick={() => onClick(item)}
          p={2}
          sx={{ color: isActive ? 'secondary' : 'dark.1' }}
        >
          {item}
        </Button>
      )
    })

  const renderPrev = () => {
    const startPage = pages[0]
    return (
      <Button
        variant="icon"
        disabled={page === startPage}
        icon={<MdKeyboardArrowLeft size="28px" />}
        onClick={() => onClick(prevPage)}
      />
    )
  }

  const renderNext = () => {
    const endPage = pages[pages.length - 1]
    return (
      <Button
        variant="icon"
        disabled={page === endPage}
        icon={<MdKeyboardArrowRight size="28px" />}
        onClick={() => onClick(nextPage)}
      />
    )
  }

  return (
    <Center justifyContent="flex-end">
      {renderPrev()}
      {renderFirst()}
      {renderFirstEllipsis()}
      {renderPages()}
      {renderLastEllipsis()}
      {renderLast()}
      {renderNext()}
    </Center>
  )
}
