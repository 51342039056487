import React from 'react'
import { Flex } from 'reflexbox'
import { FormField } from 'components/form'
import { useDropzone } from 'react-dropzone'
import { MdAddAPhoto } from 'react-icons/md'
import { Image } from 'components/core'

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      file.preview = reader.result
      file.alt = ''
      resolve(file)
    }
  })
}

export default ({ id, label, errMsg, containerProps, value, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const files = await Promise.all(
        acceptedFiles.map((file) => readFile(file)),
      )
      onDrop(files[0])
    },
    accept: 'image/*',
    multiple: false,
    noDrag: true,
  })

  const renderItem = () => {
    let src = value?.preview || value?.path
    if (!src) return renderEmpty()

    return (
      <Image
        src={src}
        alt={value.alt}
        width={1}
        height="10rem"
        sx={{
          objectFit: 'contain',
          opacity: 1,
          transition: '.2s ease',
          backfaceVisibility: 'hidden',
        }}
      />
    )
  }

  const renderEmpty = () => (
    <Flex justifyContent="center" alignItems="center" width={1} color="grey.4">
      <MdAddAPhoto size="48px" />
    </Flex>
  )

  return (
    <FormField id={id} label={label} errMsg={errMsg} {...containerProps}>
      <Flex
        flexWrap="wrap"
        alignContent="center"
        variant="dropzone"
        sx={{
          p: 3,
          color: 'dark.0',
          borderColor: isDragActive ? 'accent.1' : 'dark.0',
          borderWidth: '2px',
          borderRadius: '3px',
          borderStyle: 'dashed',
          outline: 'none',
          transition: 'border .24s ease-in-out',
          cursor: 'pointer',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {renderItem()}
      </Flex>
    </FormField>
  )
}
