import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  showDate,
  getSelectOption,
  handleTextChange,
  getDate,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Definition, Table } from 'components/core'
import { Select, NumberInput, TextArea, DateInput } from 'components/form'
import { printHtml } from 'utilities/print'
import { handleDelete, setBalances } from 'actions/ticket'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { getPartOptions, getSkuOptions } from 'actions/part'
import { getLocation } from 'actions/location'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}, message) => {
  const locationOptions = getLocationOptions(value.locations, message)
  return {
    id: value.id,
    status: value.status || 'PENDING',
    hash: value.hash,
    fromTechName: value.fromLocationName || '',
    toTechName: value.toLocationName || '',
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    inputValues: getInitialInput(),
    editInputValues: {},
    parts: value.parts || [],
    partOptions: getPartOptions(value.parts),
    skuOptions: getSkuOptions(value.parts),
    locations: value.locations || [],
    locationOptions,
    ...initializeState({
      transDate: getDate(value.transDate),
      fromTechId: getSelectOption(
        value.locations,
        value.fromLocationId,
        'id',
        'name',
      ),
      toTechId: getSelectOption(
        value.locations,
        value.toLocationId,
        'id',
        'name',
      ),
      memo: value.memo || '',
    }),
  }
}

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    sku: '',
    balance: 0,
    quantity: 1,
  }
}

function getLocationOptions(locations, message) {
  if (!locations) return []
  const warehouses = []
  const techs = []
  locations.forEach(({ type, id, name }) => {
    const result = { label: name, value: id }

    switch (type) {
      case 'TECHNICIAN':
        techs.push(result)
        break
      default:
        warehouses.push(result)
    }
  })
  return [
    { label: message({ id: 'location.type.WAREHOUSE' }), options: warehouses },
    { label: message({ id: 'location.type.TECHNICIAN' }), options: techs },
  ]
}

function getToLocationOptions(locations, fromTechId) {
  if (!fromTechId.value) return []
  const location = getLocation(locations, fromTechId.value)
  return locations
    .filter((item) => item.type === location.type && item.id !== location.id)
    .map(({ id, name }) => ({ value: id, label: name }))
}

const validation = {
  fromTechId: [{ type: 'required', message: 'error.required' }],
  toTechId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'id', label: 'field.ticketId' },
  {
    id: 'transDate',
    label: 'field.date',
    render: (state) => showDate(state.transDate),
  },
  {
    id: 'fromTechId',
    label: 'move.field.fromTech',
    render: (state) => state.fromTechName,
  },
  {
    id: 'toTechId',
    label: 'move.field.toTech',
    render: (state) => state.toTechName,
  },
  { id: 'memo', label: 'field.memo' },
]

const columns = [
  { id: 'productVariantName', label: 'field.partName' },
  { id: 'sku', label: 'field.sku' },
  {
    id: 'location',
    label: 'part.field.location',
    render: ({ row }) => row.extra?.location,
  },
  { id: 'quantity', label: 'field.quantity', align: 'right' },
  { id: 'price', label: 'field.subTotal', align: 'right' },
]

export const labels = ({ state }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.ticketItems = (
    <Table showSeq columns={columns} rows={state.ticketItems} />
  )
  return content
}

export const fields = ({ app, session, state, setState, profile }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="field.date"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    fromTechId: (
      <Select
        isClearable={false}
        id="fromTechId"
        label="move.field.fromTech"
        placeholder="move.field.fromTech"
        options={state.locationOptions}
        value={state.fromTechId}
        onChange={onSelectChange('fromTechId', async ({ value }) => {
          const { locations, ticketItems, oldTicketItems } = state
          const location = getLocation(locations, value)
          const balances = await fetchBalances(
            app,
            session,
            location.type,
            value,
            ticketItems.map((item) => item.productVariantId),
            oldTicketItems,
          )
          setBalances(ticketItems, balances)
          const inputValues = getInitialInput()
          return { ticketItems, inputValues, toTechId: {} }
        })}
        errMsg={state.__error__.fromTechId}
      />
    ),
    toTechId: (
      <Select
        isClearable={false}
        id="toTechId"
        label="move.field.toTech"
        placeholder="move.field.toTech"
        options={getToLocationOptions(state.locations, state.fromTechId)}
        value={state.toTechId}
        onChange={onSelectChange('toTechId')}
        errMsg={state.__error__.toTechId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    ticketItems: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.partOptions}
                onChange={async ({ value }) => {
                  const { locations, fromTechId } = state
                  const location = getLocation(locations, fromTechId.value)
                  const part = state.parts.find(({ id }) => id === value)
                  const unitPrice = part.price || 0
                  const balance = await fetchBalance(
                    app,
                    session,
                    location.type,
                    location.id,
                    value,
                    state.oldTicketItems,
                  )
                  return {
                    sku: { value: part.id, label: part.sku },
                    unitPrice,
                    balance,
                  }
                }}
              />
            ),
            getValue: (row) => {
              return getSelectOption(state.partOptions, row.productVariantId)
            },
          },
          {
            id: 'sku',
            label: 'field.sku',
            renderInput: () => (
              <Select
                isClearable={false}
                options={state.skuOptions}
                onChange={async ({ value }) => {
                  const { locations, fromTechId } = state
                  const location = getLocation(locations, fromTechId.value)
                  const part = state.parts.find(({ id }) => id === value)
                  const unitPrice = part.price || 0
                  const balance = await fetchBalance(
                    app,
                    session,
                    location.type,
                    location.id,
                    value,
                    state.oldTicketItems,
                  )
                  const result = { value: part.id, label: part.name }
                  return { productVariantName: result, unitPrice, balance }
                }}
              />
            ),
            getValue: (row) => {
              return getSelectOption(state.skuOptions, row.productVariantId)
            },
          },
          {
            id: 'balance',
            label: 'field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '96px',
            align: 'right',
            renderInput: ({ row }) => (
              <NumberInput min="1" max={row?.balance} />
            ),
            defaultInputValue: 1,
          },
        ]}
        rows={state.ticketItems}
        showAddInput
        showDeleteIcon
        inputValues={state.inputValues}
        onInputChange={(value) => setState({ ...state, inputValues: value })}
        onAdd={({ row }) => {
          const { sku, productVariantName, balance, quantity } = row
          if (!productVariantName.value) return false

          const productVariantId = productVariantName.value
          const part = state.parts.find(({ id }) => id === productVariantId)
          const ticketItems = cloneDeep(state.ticketItems)
          ticketItems.push({
            productVariantId,
            productVariantName: productVariantName.label,
            sku: sku.label,
            balance: parseInt(balance),
            quantity: parseInt(quantity),
            price: parseFloat(part.price),
            extra: { location: part.extra?.location },
          })
          const inputValues = getInitialInput()
          setState({ ...state, ticketItems, inputValues })
          return true
        }}
        // onEdit={({ row, index }) => {
        //   const { sku, productVariantName, balance, quantity } = row
        //   if (!productVariantName.value) return false

        //   const productVariantId = productVariantName.value
        //   const part = state.parts.find(({ id }) => id === productVariantId)
        //   const ticketItems = cloneDeep(state.ticketItems)
        //   const ticketItem = {
        //     ...ticketItems[index],
        //     productVariantId,
        //     productVariantName: productVariantName.label,
        //     sku: sku.label,
        //     balance: parseInt(balance),
        //     quantity: parseInt(quantity),
        //     price: parseFloat(part.price),
        //     extra: { location: part.extra?.location },
        //   }
        //   ticketItems.splice(index, 1, ticketItem)
        //   setState({ ...state, ticketItems })
        //   return true
        // }}
        onDelete={({ index }) => {
          const ticketItems = cloneDeep(state.ticketItems)
          ticketItems.splice(index, 1)
          setState({ ...state, ticketItems })
        }}
      />
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  profile,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, profile, id })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editMove(state, app, session)
      : await addMove(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartMoveTicket

    history.push(`/move/${id}/view`)
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmMove({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  handleScan: async (value) => {
    const { locations, fromTechId } = state
    const location = getLocation(locations, fromTechId?.value)
    if (!location || !location.id) {
      const errMessage = 'move.error.location.missing'
      return { name: '', balance: 0, quantity: 0, errMessage }
    }

    const part = state.parts.find(({ sku }) => sku === value)
    if (!part) {
      const errMessage = 'error.part.notFound'
      return { name: '', balance: 0, quantity: 0, errMessage }
    }

    const balance = await fetchBalance(
      app,
      session,
      location.type,
      location.id,
      part.id,
      state.oldTicketItems,
    )
    const ticketItemIdx = state.ticketItems.findIndex(
      (item) => item.productVariantId === part.id,
    )
    const ticketItem = state.ticketItems[ticketItemIdx]
    const quantity = ticketItem ? ticketItem.quantity + 1 : 1
    if (quantity > balance) {
      const errMessage = 'error.inventoryBalance.insufficientQuantity'
      return {
        name: part.name,
        balance,
        quantity: ticketItem?.quantity || 0,
        errMessage,
      }
    }

    const ticketItems = cloneDeep(state.ticketItems || [])
    if (ticketItem) {
      ticketItem.quantity = quantity
      ticketItems.splice(ticketItemIdx, 1, ticketItem)
    } else {
      const unitPrice = parseFloat(part.price)
      ticketItems.push({
        productVariantId: part.id,
        productVariantName: part.name,
        sku: part.sku,
        balance,
        quantity,
        price: unitPrice * quantity,
        extra: { location: part.extra?.location },
      })
    }
    setState({ ...state, ticketItems })
    return { name: part.name, balance, quantity }
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partMove', { session, app, id, hash })
    if (!ok) return false

    history.push('/move')
    return true
  },
  handlePrint: () => {
    const title = 'move.title.view'
    const field = defs.map(({ id, label, render }) => {
      const value = render ? render(state) : state[id]
      return { label, value }
    })
    const list = { columns, rows: state.ticketItems }
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: list },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, profile, id }) {
  const locationInput = { type: ['PART_WAREHOUSE', 'TECHNICIAN'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      parts {
        id
        name
        sku
        price
        extra
      }
      partMoveTicket(id: $id) {
        fromLocationId
        fromLocationName
        fromLocationType
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
      }
      partMoveTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partMoveTicket || {}
  const ticketItems = data.partMoveTicketItems?.map((item) => ({
    ...item,
    location: item.extra?.location,
    price: item.price,
  }))
  const oldTicketItems = cloneDeep(ticketItems)

  if (profile === 'edit' && ticketItems.length > 0) {
    const { fromLocationId, fromLocationType } = ticket
    const balances = await fetchBalances(
      app,
      session,
      fromLocationType,
      fromLocationId,
      ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
    )
    setBalances(ticketItems, balances)
  }

  return {
    id,
    hash: ticket.hash,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    transDate: ticket.transDate,
    memo: ticket.extra?.memo,
    status: ticket.status,
    ticketItems,
    oldTicketItems,
    parts: data.parts,
    locations: data.locations,
  }
}

async function addMove(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartMoveTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editMove(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartMoveTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, memo, fromTechId, toTechId, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (item.price !== newItem.price) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate,
    fromLocationId: fromTechId.value,
    toLocationId: toTechId.value,
    extra: { memo },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function confirmMove({ session, app, id, hash }) {
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmPartMoveTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
