import { request } from 'utilities/request'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { showAddress, showDate } from 'utilities/form'

export const filters = [
  {
    id: 'transDate',
    label: 'report.field.ticketDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'repairDate',
    label: 'report.field.repairDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'partName',
    label: 'field.partName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'field.sku',
    input: textFilter,
  },
]

export const columns = ({ message }) => [
  {
    id: 'transDate',
    label: 'report.field.ticketDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'repairId',
    label: 'report.field.repairNo',
    format: ['html', 'excel'],
  },
  {
    id: 'repairDate',
    label: 'report.field.repairDate',
    format: ['html', 'excel'],
  },
  {
    id: 'repairType',
    label: 'report.field.repairType',
    format: ['html', 'excel'],
  },
  {
    id: 'repairTag',
    label: 'report.field.repairTag',
    format: ['html', 'excel'],
    render: ({ row }) => row.repairTag?.join(', '),
  },
  {
    id: 'receiptNo',
    label: 'report.field.receiptNo',
    format: ['excel'],
  },
  {
    id: 'techName',
    label: 'report.field.tech',
    format: ['html', 'excel'],
    render: ({ row }) => (row.techName ? row.techName.join(', ') : ''),
  },
  {
    id: 'recipientContact',
    label: 'report.field.recipientContact',
    format: ['excel'],
  },
  {
    id: 'phone',
    label: 'report.field.phone',
    format: ['excel'],
  },
  {
    id: 'cellphone',
    label: 'report.field.cellphone',
    format: ['excel'],
  },
  {
    id: 'address',
    label: 'report.field.address',
    format: ['excel'],
    render: ({ row }) => (row.address ? showAddress(row.address, message) : ''),
  },
  {
    id: 'productVariantName',
    label: 'part.field.partName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'price',
    label: 'field.price',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
  },
  {
    id: 'partMemo',
    label: 'report.field.sellMemo',
    format: ['excel'],
  },
  {
    id: 'symptom',
    label: 'report.field.symptom',
    format: ['excel'],
  },
  {
    id: 'solution',
    label: 'report.field.solution',
    format: ['excel'],
  },
  {
    id: 'productVariantName',
    label: 'report.field.productVariantName',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.products) return ''
      return row.products?.map((item) => item.productVariantName).join(', ')
    },
  },
  {
    id: 'sku',
    label: 'report.field.sku',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.products) return ''
      return row.products?.map((item) => item.sku).join(', ')
    },
  },
  {
    id: 'model',
    label: 'report.field.model',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.products) return ''
      return row.products?.map((item) => item.productModel).join(', ')
    },
  },
  {
    id: 'serialNo',
    label: 'report.field.serialNo',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.products) return ''
      return row.products?.map((item) => item.productSerialNo).join(', ')
    },
  },
  {
    id: 'productRepairDate',
    label: 'report.field.productRepairDate',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.products) return ''
      return row.products?.map((item) => item.productRepairDate).join(', ')
    },
  },
  {
    id: 'productMemo',
    label: 'report.field.productMemo',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.products) return ''
      return row.products?.map((item) => item.productMemo).join(', ')
    },
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.repairList'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: PartReportInput) {
      partRepairReportCount(input: $input)
      partRepairReports(input: $input) {
        repairDate
        transDate
        repairId
        receiptNo
        techName
        productVariantName
        quantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.partRepairReports,
    pagination: getPagination(page, countPerPage, data.partRepairReportCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const input = getDataInput({ filterValues, isPart: false })
  const productInput = getDataInput({ filterValues, isPart: true })
  const variables = { input, productInput }
  const query = `
    query($input: PartReportInput, $productInput: PartReportInput) {
      partRepairReports(input: $input) {
        transDate
        repairId
        repairDate
        repairType
        repairTag
        receiptNo
        techName
        phone
        cellphone
        address
        productVariantName
        sku
        quantity
        price
        memo
        partMemo
        recipientContact
        symptom
        solution
      }
      partRepairProducts(input: $productInput) {
        repairId
        ticketItemId
        productVariantName
        sku
        productModel
        productSerialNo
        productRepairDate
        productMemo
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const productMap = data.partRepairProducts.reduce((result, item) => {
    const products = result[item.repairId] || []
    if (!products.some((i) => i.ticketItemId === item.ticketItemId)) {
      products.push(item)
      result[item.repairId] = products
    }
    return result
  }, {})

  return data.partRepairReports.map((item) => {
    item.products = productMap[item.repairId]
    return item
  })
}

function getDataInput({ filterValues = [], pagination, isPart = false }) {
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const repairDate = filterValues.find(({ id }) => id === 'repairDate')
  const partName = filterValues.find(({ id }) => id === 'partName')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const input = {
    transDate: transDate?.value,
    repairDate: repairDate?.value,
    orderBy: [
      // { key: 'repairDate', sort: 'DESC' },
      { key: 'repairId', sort: 'DESC' },
    ],
  }
  if (isPart) {
    input.partName = partName?.value
    input.partSku = sku?.value
  } else {
    input.productVariantName = partName?.value
    input.sku = sku?.value
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
