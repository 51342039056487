import { getMessage } from './app'

export const printHtml = ({ title, header, footer, content, message }) => {
  try {
    const frame = document.getElementById('ticket-default')
    const doc = (frame.contentWindow || frame.contentDocument).document
    doc.write(renderHead(title, message))
    doc.write('<body onload="this.focus(); this.print();">')
    doc.write('<table border="0" width="100%">')

    if (header) {
      doc.write(renderHeader(header, message))
    }

    if (footer) {
      doc.write(renderFooter(footer, message))
    }

    doc.write(`<tbody class="tbody"><tr><td width="100%">`)
    doc.write(renderContent(content, message))
    doc.write('</td></tr></tbody>')
    doc.write('</table>')
    doc.write('</body>')
    doc.close()
  } catch (e) {
    console.error(e)
  }
}

function renderHead(title, message) {
  return `
    <head>
      <title>${message({ id: title })}</title>
      <style>
        html, body {
          margin: 0;
          padding: 0;
        }
        @page {
          margin: 16px;
        }
        .grid-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }
        .grid-item {
          padding: 4px;
        }
        .grid-item.inline {
          display: flex;
          align-items: flex-start;
          line-height: 1;
        }
        .theader {
          position: sticky;
          top: 0;
          right: 0;
          left: 0;
        }
        .tfooter {
          position: sticky;
          bottom: 0;
          right: 0;
          left: 0;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 8px;
          border-bottom: 2px solid #000;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 32px;
        }
        .title {
          margin: 0;
          padding: 0 0 8px;
          font-weight: normal;
          font-size: 16px;
        }
        .sub-title {
          margin: 16px 0 0;
          padding: 0 0 8px;
          font-weight: normal;
        }
        .label {
          font-size: 14px;
          white-space: nowrap;
        }
        .field {
          font-size: 14px;
        }
        .inline .label {
          margin-right: 8px;
          font-size: 14px;
        }
        .inline .label:after {
          content: ":";
        }
        .inline .label-checkbox {
          margin-right: 4px;
          font-size: 14px;
        }
        .text-input-wrap {
          display: flex;
          align-items: flex-start;
          margin-bottom: 8px;
          font-size: 14px;
          line-height: 1.5;
        }
        .text-input-wrap .value {
          display: flex;
          align-items: space-between;
          width: 100%;
        }
        .text-input {
          flex: 1;
          border-bottom: 1px solid #000;
        }
        .text {
          font-size: 14px;
        }
        .list {
          width: 100%;
          border-collapse: collapse;
          border: 2px solid #000;
          margin: 0 0 16px;
        }
        .list th, .list td {
          padding: 4px 8px;
        }
        .list th {
          border-bottom: 2px solid #000;
          font-weight: normal;
          font-size: 14px;
          white-space: nowrap;
        }
        .list td {
          border-bottom: 1px solid #d0d0d0;
          font-size: 14px;
        }
        .list-vertical {
          display: flex;
          flex-direction: column;
        }
        .right {
          text-align: right;
        }
        .left {
          text-align: left;
        }
        .center {
          text-align: center;
        }
        .inactive {
          color: #d0d0d0;
        }
        .pagebreak { 
          page-break-after: always; 
        }
        .space { 
          padding: 8px 0; 
        }
        .emptyblock {
          margin: 64px 0;
        }
        ul.checklist {
          font-size: 14px;
          list-style: none;
          padding: 0;
          margin: 0;
        }
        ul.checklist li::before {
          content: "\\2610";
          padding-right: 8px;
        }
        .checklist-wrap {
          display: inline-block;
          vertical-align: text-top;
          line-height: 1.3;
        }
      </style>
    </head>
  `
}

function renderHeader(value, message) {
  const { title, address, phone } = value
  return `
    <thead class="theader">
      <tr>
        <td class="header">
          <div class="title">${getMessage(message, title)}</div>
          <div class="list-vertical right text">
            <div>${message({ id: address })}</div>
            <div>${message({ id: phone })}</div>
          </div>
        </td>
      </tr>
    </thead>
  `
}

function renderContent(content, message) {
  if (!Array.isArray(content)) content = [content]

  let str = ''
  content.forEach((item) => {
    switch (item.type) {
      case 'title':
        str += renderTitle(item.value, message)
        break
      case 'subTitle':
        str += renderSubTitle(item.value, message)
        break
      case 'field':
        str += renderFields(
          item.value,
          item.display,
          item.template,
          item.areas,
          message,
        )
        break
      case 'list':
        str += renderTable(item.value, item.title, message)
        break
      case 'checklist':
        str += renderCheckList(item)
        break
      case 'text':
        str += renderText(item.value, item.className)
        break
      case 'textInput':
        str += renderTextInput(item)
        break
      case 'textarea':
        str += renderTextarea(item.label)
        break
      case 'column':
        str += renderColText(item.value, item.title, message)
        break
      case 'block':
        str += renderBlock(item.value, message)
        break
      case 'break':
        str += renderBreak()
        break
      case 'space':
        str += renderSpace()
        break
      default:
        str += renderEmptyBlock()
    }
  })
  return str
}

function renderTitle(title, message) {
  return `<h2 class="title">${message({ id: title })}</h2>`
}

function renderSubTitle(title, message) {
  return `<h3 class="sub-title">${message({ id: title })}</h3>`
}

function renderFields(fields, display, template, areas, message) {
  let style = ''
  if (template) style += `grid-template-columns: ${template};`
  if (areas) style += `grid-template-areas: ${areas};`
  return `
    <div class="grid-container" style="${style}">
      ${fields
        .map((field) => renderField(field, display, areas, message))
        .join('')}
    </div>
  `
}

function renderField(field, display, areas, message) {
  // if (!field.value && field.value !== 0) return ''
  const empty = display === 'inline' ? '' : '\u2014'
  let style = ''
  if (areas) style = `grid-area: ${field.id};`
  const labelClass = field.labelClass || 'label'
  return `
    <div class="grid-item ${display}" style="${style}">
      <div class="${labelClass}">${message({ id: field.label })}</div>
      <div class="field">${field.value || empty}</div>
    </div>
  `
}

function renderTable(value, title, message) {
  const { rows, columns, showSeq } = value
  const cols = columns
    .filter(({ format = ['print'] }) => format.includes('print'))
    .filter(({ show = true }) => show)

  if (showSeq) cols.unshift({ id: '__SEQ__', label: 'field.seq' })
  let result = `
    <table class="list">
      <thead>
        <tr>${renderTableHeaderRow(cols, message)}</tr>
      </thead>
      <tbody>
        ${renderTableRows(cols, rows, showSeq)}
      </tbody>
    </table>
  `
  if (title) {
    result = `<div>${title}${result}</div>`
  }
  return result
}

function renderTableHeaderRow(columns, message) {
  return columns
    .map((column) => {
      const { align = 'left' } = column
      const cell = message({ id: column.label })
      return `<th class="${align}">${cell}</th>`
    })
    .join('')
}

function renderTableRows(columns, rows, showSeq) {
  if (!rows || rows.length === 0) {
    let colLength = columns.length
    if (showSeq) colLength++
    return `<tr><td class="center inactive" colspan="${colLength}">NO DATA</td></tr>`
  }
  return rows
    .map((row, index) => {
      return `<tr>${renderTableRow(columns, row, index)}</tr>`
    })
    .join('')
}

function renderTableRow(columns, row, index) {
  return columns
    .map((column) => {
      const { align = 'left' } = column
      let cell = renderTableCell(column, row, index)
      if (cell === undefined || cell === null) cell = ''
      return `<td class="${align}">${cell}</td>`
    })
    .join('')
}

function renderTableCell(column, row, index) {
  if (column.id === '__SEQ__') return index + 1
  if (column.render) return column.render({ row })
  return row[column.id]
}

function renderFooter(values, message) {
  const renderValue = (value) => {
    if (value === '__INPUT__') {
      return '<div style="flex: 1; border-bottom: 2px solid #000;">&nbsp;</div>'
    }
    return `<div class="field">${value}</div>`
  }
  const fields = values.map((item) => {
    const label = message({ id: item.label })
    const value = renderValue(item.value)
    return `<div class="label" style="padding-right: 4px">${label}:</div>${value}`
  })
  return `
    <tfoot class="tfooter">
      <tr>
        <td class="footer">
          ${fields.join('&nbsp;')}
        </td>
      </tr>
    </tfoot>
  `
}

function renderText(value, className = 'text') {
  if (!value) return ''
  if (Array.isArray(value)) {
    return value
      .map((item) => `<div class="${className}">${item}</div>`)
      .join('')
  }
  return `<div class="${className}">${value}</div>`
}

function renderTextInput(item) {
  let { label, value } = item
  let result = ''
  const textInput = '<div class="text-input">&nbsp;</div>'

  if (label) result += `<div class="label">${label}</div>`
  if (!value) value = textInput

  value = value.replaceAll('__INPUT__', textInput)
  result += `<div class="value">${value}</div>`

  return `<div class="text-input-wrap inline">${result}</div>`
}

function renderTextarea(label) {
  return `
    <div>
      ${label}
      <div class="emptyblock"></div>
    </div>
  `
}

function renderColText(list, title, message) {
  if (!list) return ''
  const style = `grid-template-columns: 1fr 1fr`
  let result = `
    <div class="grid-container" style="${style}">
      ${list.map((item) => renderContent(item, message)).join('')}
    </div>
  `
  if (title) {
    result = `<div style="margin-bottom: 16px">${title}${result}</div>`
  }
  return result
}

function renderBlock(value, message) {
  return `<div>${renderContent(value, message)}</div>`
}

function renderEmptyBlock() {
  return `<div class="emptyblock"></div>`
}

function renderCheckList(value, depth = 0) {
  const { label, value: list } = value
  let result = `
    <ul class="checklist">
      ${list
        .map((item) => {
          if (typeof item === 'string') return `<li>${item}</li>`
          return `<li>${renderCheckList(item, depth + 1)}</li>`
        })
        .join('')}
    </ul>
  `
  if (label) {
    const style = depth === 0 ? 'display: block; margin-bottom: 16px' : ''
    result = `<div class="checklist-wrap" style="${style}">${label}${result}</div>`
  }
  return result
}

function renderBreak() {
  return `<div class="pagebreak"></div>`
}

function renderSpace() {
  return `<div class="space"></div>`
}
