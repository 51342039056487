import React, { useState, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { initialState, fields, handlers } from 'models/partScanModal'
import { Box } from 'reflexbox'
import { Button, Center, Definition, Grid, Message } from 'components/core'

export default ({ open, data, action }) => {
  const { formatMessage: message } = useIntl()
  const ref = useRef(null)
  const tableRef = useRef(null)
  const [state, setState] = useState(initialState())
  const form = fields({ state, setState, ref, message, data })
  const handler = handlers({ state, setState, action })

  const scrollToBottom = () => {
    if (!tableRef) return
    tableRef.current.scrollTop = tableRef.current.scrollHeight
  }

  useEffect(() => {
    if (open) {
      ref.current.focus()
    }
  }, [open])

  useEffect(() => {
    scrollToBottom()
  }, [data])

  return (
    <Box
      height="100vh"
      width="100vw"
      bg="light.0"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1001,
        transform: open ? 'translateY(0px)' : 'translateY(110vh)',
        opacity: open ? 1 : 0,
        transition: `transform .3s ease-out, opacity 0.15s ease-out`,
      }}
    >
      <Grid
        height="100%"
        cols={['1fr', '2fr 4fr']}
        onClick={() => ref.current.focus()}
      >
        <Center
          px={4}
          flexDirection="column"
          sx={{
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: 'light.3',
          }}
        >
          <Box as="form" onSubmit={handler.handleSubmit}>
            {form.sku}
            <Message
              display="block"
              mt={2}
              id={state.errMessage}
              color="error.1"
            />
            <Definition
              mt={5}
              direction="row"
              label="part.field.partName"
              value={state.name}
            />
            <Definition
              mt={2}
              direction="row"
              label="field.balance"
              value={state.balance}
            />
            <Definition
              mt={2}
              direction="row"
              label="part.field.scanQuantity"
              value={state.quantity}
            />
            <Definition
              mt={4}
              direction="row"
              label="part.field.totalQuantity"
              value={handler.getTotalQuantity(data.ticketItems)}
            />
          </Box>
          <Button
            variant="secondary"
            mt={5}
            text="btn.back"
            onClick={action.handleClose}
          />
        </Center>
        <Box ref={tableRef} sx={{ overflowY: 'auto' }} pb={5}>
          {form.ticketItems}
        </Box>
      </Grid>
    </Box>
  )
}
