import React, { useContext } from 'react'
import { keyframes } from '@emotion/core'
import { ALERT_REMOVE } from 'constants/actionType'
import { SessionContext } from 'contexts'
import { Flex, Box } from 'reflexbox'
import { MdClose } from 'react-icons/md'
import { renderMessage } from 'utilities/app'

const slide = keyframes`
  from { transform: translate(0, -8px) }
  to { transform: translate(0) }
`

const Wrapper = ({ hidden, children }) => (
  <Box
    display={hidden ? 'none' : 'block'}
    width={['80%', '30%']}
    ml={['-40%', '-15%']}
    overflow="hidden"
    sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      zIndex: 10000,
    }}
  >
    {children}
  </Box>
)

const Close = ({ onClick }) => (
  <Flex as="a" onClick={onClick} sx={{ fontSize: 4, cursor: 'pointer' }}>
    <MdClose />
  </Flex>
)

export default ({ values }) => {
  const { dispatch } = useContext(SessionContext)
  const handleClick = (idx) => () => dispatch({ type: ALERT_REMOVE, idx })

  return (
    <Wrapper hidden={values.length === 0}>
      {values.map((value, idx) => {
        setTimeout(handleClick(idx), 5000)
        return (
          <Flex
            key={idx}
            variant={value.type}
            alignItems="center"
            m={3}
            p={3}
            sx={{ animation: `${slide} 0.2s linear` }}
          >
            {renderMessage(value.message, { flex: 1 })}
            <Close onClick={handleClick(idx)} />
          </Flex>
        )
      })}
    </Wrapper>
  )
}
