import React from 'react'
import { useHistory } from 'react-router-dom'
import { renderMessage } from 'utilities/app'
import { Box, Flex } from 'reflexbox'
import { Icon } from 'components/core'

export default ({
  show = true,
  disabled,
  icon,
  iconProps,
  rightIcon,
  rightIconProps,
  href,
  text,
  textProps,
  tooltip,
  children,
  onClick,
  variant = 'link',
  ...props
}) => {
  if (!show) return null
  const history = useHistory()
  const getClickProp = () => {
    if (disabled) return { onClick: () => {} }
    if (href) {
      if (href.startsWith('http')) return { href }
      return { onClick: () => history.push(href) }
    }
    return { onClick }
  }

  return (
    <Box
      as="a"
      display="inline-flex"
      justifyContent="flex-start"
      alignItems="center"
      tx="variants"
      variant={disabled ? 'disabledLink' : variant}
      {...getClickProp()}
      {...props}
    >
      <Icon mr={text || children ? 2 : 0} {...iconProps} value={icon} />
      {text ? renderMessage(text, textProps) : children}
      {rightIcon && (
        <Flex
          alignItems="center"
          fontSize={3}
          ml={children ? 2 : 0}
          {...rightIconProps}
        >
          {rightIcon}
        </Flex>
      )}
    </Box>
  )
}
