import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { Button } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete, MdEdit } from 'react-icons/md'

export const columns = ({ setState, session, app, action }) => [
  {
    id: 'name',
    label: 'warehouse.field.name',
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'warehouse.title.delete',
                text: 'warehouse.message.delete',
                onSubmit: handleDelete({
                  setState,
                  session,
                  app,
                  id: row.id,
                  action,
                }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ setState, session, app }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app })
    setState(data)
  },
})

async function getData({ session, app }) {
  const variables = {
    input: { type: ['PART_WAREHOUSE'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data.locations
}

const handleDelete = ({ session, app, id, action }) => async () => {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deletePartWarehouse(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) {
    action.handleLoad()
    action.handleClose()
  }

  return ok
}
